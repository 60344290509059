import React, { Dispatch, SetStateAction, MouseEvent } from 'react'
import { Tooltip, Button, toCssPrefix, breakpoints, saveSelectedSessionMarket, useLocation } from '@ecommerce/shared'
import styled, { css } from 'styled-components'
import { Icon } from '../../Icon/Icon'
import { useLocationSelectorMessageQuery } from '../../../graphql/global'

const { cssPrefix, toPrefix } = toCssPrefix('MainNavBar__tooltip-location-')

type Props = {
  widthLabelLocation?: number
  setTooltipLocationIsActive: Dispatch<SetStateAction<boolean>>
  onLocationSelectorClick?: (e: MouseEvent<HTMLButtonElement>) => void
  isMobile?: boolean
  isTablet?: boolean
}

const Wrapper = styled(Tooltip)<Omit<Props, 'setTooltipLocationIsActive'>>`
  width: 327px;
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  top: ${({ isMobile }) => (isMobile ? '166px' : '65px')};
  right: 30px;
  color: ${({ theme }) => theme.colors.black};
  padding: 24px 14px;
  ${({ isMobile }) =>
    isMobile
      ? css`
          left: calc(50% - 163.5px);
        `
      : ''}

  &:after {
    background: ${({ theme }) => theme.colors.white};
    right: ${({ isMobile }) => (isMobile ? '150px' : '48px')};
  }

  .${cssPrefix} {
    &container {
      display: flex;
      flex-direction: column;
      align-items: center;
      ${({ isMobile }) =>
        isMobile
          ? css`
              width: 295px;
            `
          : ''}
    }

    &header {
      display: flex;
      width: 100%;
      height: 20px;
      align-items: center;
      justify-content: space-between;
      margin-block-start: 0;
      margin-block-end: 16px;
      &:hover {
        cursor: pointer;
      }
      &-icon {
        margin-inline-end: -2px;
      }
    }

    &title {
      font-size: 15px;
      line-height: 20px;
      height: inherit;
      letter-spacing: 0.1px;
      font-weight: bold;
      margin-block: 0;
    }

    &content {
      font-size: 12px;
      line-height: 16px;
      text-align: justify;
      margin: 0 0 12px;
    }

    &button {
      width: 295px;
      height: 30px;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.06px;
    }
  }

  @media (${breakpoints.tabletPortrait.min}) {
    width: 340px;
    padding: 11.5px 14px;
    top: 165px;
    ${({ isTablet }) =>
      isTablet
        ? css`
            left: calc(40% - 170px);
          `
        : ''}

    &:after {
      left: 50px;
    }

    .${cssPrefix} {
      &header {
        margin-bottom: 24px;
      }

      &title {
        font-size: 16px;
        line-height: 22px;
      }

      &content {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.06px;
        margin-bottom: 20px;
      }

      &button {
        width: 177px;
      }
    }
  }

  @media (${breakpoints.desktop.min}) {
    width: 368px;
    padding: 11.5px 18px;
    right: ${({ widthLabelLocation }) => (widthLabelLocation ? `${70 + widthLabelLocation}px` : '0px')};
    top: 62px;

    &:after {
      left: 288px;
    }
  }
`

const LocationSelectorTooltip = ({
  widthLabelLocation,
  setTooltipLocationIsActive,
  onLocationSelectorClick,
  isMobile,
  isTablet,
}: Props) => {
  const { locationSelectorMessage } = useLocationSelectorMessageQuery()

  return (
    <Wrapper isMobile={isMobile} isTablet={isTablet} widthLabelLocation={widthLabelLocation} tipPosition="right">
      <div className={toPrefix('container')}>
        <div className={toPrefix('header')}>
          <p className={toPrefix('title')}>Selecciona tu ubicación</p>
          <Icon
            className={toPrefix('header-icon')}
            iconId="close"
            size="16"
            onClick={() => {
              setTooltipLocationIsActive(false)
              saveSelectedSessionMarket()
            }}
          />
        </div>
        <p className={toPrefix('content')}>{locationSelectorMessage}</p>
        <Button
          onClick={(e) => {
            if (!onLocationSelectorClick) return
            setTooltipLocationIsActive(false)
            onLocationSelectorClick(e)
          }}
          className={toPrefix('button')}
        >
          Ingresar ubicación
        </Button>
      </div>
    </Wrapper>
  )
}

export default LocationSelectorTooltip
