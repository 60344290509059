import styled from 'styled-components'

export const Wrapper = styled.div`
  .background {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ContentModal = styled.div`
  height: auto;
  width: 360px;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding-block-end: 36px;
`

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const HeaderRow = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline-end: 8px;
  padding-block-start: 8px;
`

export const HeaderClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
  height: 100%;
  margin-bottom: 8px;
`

export const HeaderTitle = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 11px;
  margin-block: 0;
  padding-inline: 32px;
  height: 24px;
  margin-block-end: 24px;

  .header-title-text {
    flex-grow: 1;
    display: flex;
    align-self: center;
    font-size: 16px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black80};
    line-height: 22px;
    letter-spacing: 0.1px;
    margin: 0;
  }
`

export const AlertMessage = styled.p`
  padding-inline: 32px;
  margin-block-end: 32px;
`

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-inline: 32px;
  width: 100%;
`
