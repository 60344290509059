import { SwiperOptions } from 'swiper'
import { ReactIdSwiperProps } from 'react-id-swiper'

export enum ZIndex {
  default,
  low,
  medium,
  high,
  emergency,
}

export type SwiperParams = SwiperOptions | ReactIdSwiperProps
