import { Anchor, Button, ModalBackground } from '@ecommerce/shared'
import React from 'react'
import { Icon } from '../../../components/Icon/Icon'
import {
  AlertMessage,
  ContentModal,
  FooterWrapper,
  HeaderClose,
  HeaderRow,
  HeaderTitle,
  HeaderWrapper,
  Wrapper,
} from './EmptyCartAlert.styled'

interface EmptyCartModalProps {
  icon: React.ReactNode
  onClose: () => void
  onConfirm: () => void
  message: string
  title: string
}

const EmptyCartAlert = ({ icon, message, onClose, onConfirm, title }: EmptyCartModalProps) => {
  return (
    <>
      <Wrapper>
        <ModalBackground className="background">
          <ContentModal className="modal">
            <HeaderWrapper className="header">
              <HeaderRow className="header-row">
                <HeaderClose className="header-close">
                  <Anchor onClick={() => (onClose ? onClose() : null)}>
                    <Icon iconId="close" size="25" />
                  </Anchor>
                </HeaderClose>
                <HeaderTitle className="header-title">
                  {icon}
                  <p className="header-title-text">{title}</p>
                </HeaderTitle>
              </HeaderRow>
            </HeaderWrapper>
            <AlertMessage className="alert-message">{message}</AlertMessage>
            <FooterWrapper className="footer">
              <Button btnType="primary" onClick={() => onConfirm()} type="button">
                Si, vaciar carro
              </Button>

              <Button
                btnType="secondary"
                onClick={() => {
                  onClose()
                }}
                type="button"
              >
                No
              </Button>
            </FooterWrapper>
          </ContentModal>
        </ModalBackground>
      </Wrapper>
    </>
  )
}

export default EmptyCartAlert
