import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

const cssPrefix = `CategoryTile__`

export const tileBoxStyles = css`
  display: flex;
  cursor: pointer;
  overflow: hidden;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;

  :hover {
    font-weight: bold;
  }
`

const Tile = styled.div`
  ${tileBoxStyles}
  div {
    display: flex;
  }
  .${cssPrefix} {
    &tile-text {
      font-style: normal;
      font-weight: 325;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.15px;
      color: ${({ theme }) => theme.colors.black80};
    }
  }
`
export interface CategoryTileProps {
  image: string
  name: string
  slug: string
}

export const CategoryTile = (props: CategoryTileProps & HTMLAttributes<HTMLDivElement>) => (
  <Tile {...props}>
    <div>
      <span className={`${cssPrefix}tile-text`}>{props.name}</span>
    </div>
  </Tile>
)
