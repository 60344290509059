import React, { useState, useRef, useEffect } from 'react'
import { toCssPrefix } from '@ecommerce/shared'
import styled, { css } from 'styled-components'
import { FlatLocationProductCategory } from '../../types/PgPages'
import { Icon } from '../Icon/Icon'
import Subcategory from './Subcategory'

const { cssPrefix, toPrefix } = toCssPrefix('Tab__')

type StyledProps = {
  isActive?: boolean
  isRight?: boolean
}

const Wrapper = styled.div<StyledProps>`
  position: relative;
  display: inline-block;
  svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
  .${cssPrefix} {
    &category {
      padding: 11px 12px;
      font-style: normal;
      cursor: pointer;
      min-width: min-content;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-decoration: none;
      text-align: center;
      display: flex;
      align-items: center;
      background: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
      max-height: 42px;
    }
    &category:hover {
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
    }
    &dropdown {
      position: absolute;
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
      box-shadow: 0 4px 8px rgba(95, 111, 134, 0.15);
      left: 0;
      display: flex;
    }
    ${({ isRight, isActive }) => {
      if (isRight === true) {
        return css`
          &dropdown {
            left: auto;
            right: 0;
          }
        `
      }
      if (isActive === true) {
        return css`
          &category {
            background: ${({ theme }) => theme.colors.white};
            color: ${({ theme }) => theme.colors.black};
          }
        `
      }
    }}
  }
`

interface Props {
  category: FlatLocationProductCategory
  icon?: 'Promociones'
}

const Tab = ({ category, icon }: Props) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [isActive, setIsActive] = useState(false)
  const [isRight, setIsRight] = useState(false)
  const mouseInside = useRef(false)

  useEffect(() => {
    if (isActive) {
      const widthComponent = dropdownRef?.current?.offsetWidth
      const positionX = wrapperRef?.current?.offsetLeft
      const finalPositionX = (widthComponent ?? 0) + (positionX ?? 0)
      const viewportWidth = window.innerWidth

      if (finalPositionX > viewportWidth) setIsRight(true)
    }
  }, [isActive])

  const wrapperMouseEnter = () => {
    setIsActive(true)
    mouseInside.current = true
  }

  const wrapperMouseLeave = () => {
    mouseInside.current = false
    if (mouseInside.current) {
      return
    }
    setIsActive(false)
  }

  return (
    <Wrapper
      ref={wrapperRef}
      onMouseEnter={wrapperMouseEnter}
      onMouseLeave={wrapperMouseLeave}
      isActive={isActive}
      isRight={isRight}
    >
      <a
        className={toPrefix('category')}
        href={category.slug === 'promotion' ? `/${category.slug}` : `/category/${category.slug}`}
      >
        {icon && <Icon iconId={icon} />}
        {category.name}
      </a>
      {isActive && (
        <div
          className={toPrefix('dropdown')}
          ref={dropdownRef}
          onMouseEnter={wrapperMouseEnter}
          onMouseLeave={wrapperMouseLeave}
        >
          {category.subcategories &&
            category.subcategories.map((subcategory) => (
              <Subcategory subcategory={subcategory} categorySlug={category.slug} key={subcategory.categorySlug} />
            ))}
        </div>
      )}
    </Wrapper>
  )
}

export default Tab
