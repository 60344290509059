import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { Icon, IconProps } from '../Icon/Icon'

const cssPrefix = `MenuTile__`

export const tileBoxStyles = css<{ highlightColor?: string }>`
  display: flex;
  cursor: pointer;
  padding: 16px 24px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: ${({ theme, highlightColor }) => {
    if (highlightColor === 'black') {
      return theme.colors.black
    }
    if (highlightColor === 'green') {
      return theme.colors.returnability
    }
    return theme.colors.white
  }};
  margin: ${({ highlightColor }) => (highlightColor ? '0px 0px 1px' : '0px')};

  &:active {
    background-color: ${({ theme, highlightColor }) => {
      if (highlightColor === 'black') {
        return theme.colors.platinum
      }
      if (highlightColor === 'green') {
        return theme.colors.returnability60
      }
      return theme.colors.grey
    }};
  }

  &:focus,
  &:hover {
    text-decoration-line: underline;
    text-decoration-color: ${({ theme, highlightColor }) => (highlightColor ? theme.colors.white : 'initial')};
  }
`

const Tile = styled.div<{ highlightColor?: string }>`
  ${tileBoxStyles}
  .${cssPrefix} {
    &-content {
      display: flex;
      align-items: center;
    }
    &tile-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 15px;

      font-family: 'Gotham';
      font-style: normal;
      font-weight: 325;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.15px;
      color: ${({ theme, highlightColor }) => (highlightColor ? theme.colors.white : theme.colors.black80)};
    }

    &icon {
      color: ${({ theme, highlightColor }) => (highlightColor ? theme.colors.white : theme.colors.red)};
    }

    &icon-arrow {
      position: absolute;
      right: 0;
    }
  }
`
export interface MenuTileProps {
  iconId: IconProps['iconId']
  text: string
  actionType?: string
  slug?: string
  hasArrow?: boolean
  className?: string
  isVisible?: boolean
  highlightColor?: string
}

export const MenuTile = (props: MenuTileProps & HTMLAttributes<HTMLDivElement>) => (
  <Tile {...props}>
    <div className={`${cssPrefix}-content`}>
      <Icon iconId={props.iconId} size="24" className={`${cssPrefix}icon`} />
      <span className={`${cssPrefix}tile-text ${props.className ? props.className : ''}`}>{props.text}</span>
    </div>
    {props.hasArrow && <Icon iconId="chevron_right" size="25" className={`${cssPrefix}icon-arrow`} />}
  </Tile>
)
