import React from 'react'
import styled from 'styled-components'
import { toCssPrefix, ZIndex, breakpoints, useLocation } from '@ecommerce/shared'
import { NavBarMenuProps } from './NavBarMenu'
import { NavBarMenuTab } from './NavBarTab'

const { cssPrefix, toPrefix } = toCssPrefix('NavBarMenuDesktop__')

type StyledProps = {
  isRight: boolean
  isLeft: boolean
}

const Wrapper = styled.div<StyledProps>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.brandText.default};
  .${cssPrefix} {
    &container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: ${({ isRight, isLeft }) => {
        if (isLeft) {
          return 'start'
        }
        if (isRight) {
          return 'end'
        }
        return 'center'
      }};
      max-width: 270px;
      min-width: 270px;
      width: 100%;
    }
    &tab {
      width: 90px;
      &:last-child {
        margin-right: 0;
      }
      &-anchor {
        padding-top: 4px;
        span {
          font-size: 13px;
          font-weight: bold;
        }
      }
    }
    &dropdown {
      top: 72px;
      width: 100vw;
      background: ${({ theme }) => theme.colors.grey};
      position: absolute;
      left: 0px;
      z-index: ${ZIndex.low};
      min-height: 40px;
      box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
    }
  }

  @media (${breakpoints.desktop.medium}) {
    .${cssPrefix} {
      &container {
        min-width: 330px;
        width: 100%;
      }

      &tab {
        min-width: 110px;
      }
    }
  }
`

export function NavBarMenuDesktop({ tabs, className }: NavBarMenuProps) {
  const { isBolivia } = useLocation()

  return (
    <Wrapper className={className} isRight={tabs.length === 1} isLeft={isBolivia()}>
      <div className={toPrefix('container')}>
        {tabs.map((tab, idx) => {
          if (tab) {
            return (
              <NavBarMenuTab
                iconId={tab.iconId}
                key={`${tab.label}${idx}`}
                label={tab.label}
                className={`${toPrefix('tab')}`}
                anchorClassName={toPrefix('tab-anchor')}
                iconSize={24}
                onClick={tab.onClick}
                activeHighlight="background"
                iconColor="white"
                dropdownContent={tab.children}
              />
            )
          }
          return null
        })}
      </div>
    </Wrapper>
  )
}
