import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

export const MomentHeader = styled.div`
  color: ${({ theme }) => theme.colors.brandText.default};
  border: solid 2.3px ${({ theme }) => theme.colors.brandText.default};
  width: 170px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 18px;
  @media (${breakpoints.desktop.min}) {
    border: solid 3px ${({ theme }) => theme.colors.brandText.default};
    letter-spacing: 4px;
    width: 224px;
    height: 50px;
    font-size: 18px;
  }
`
