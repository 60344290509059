import React, { useState, useEffect, useCallback } from 'react'
import qs from 'qs'

declare const window: Window
const searchStateToURL = (searchState: Record<string, unknown>) =>
  searchState ? `${window.location.pathname}?${qs.stringify(searchState)}` : ''

const withURLSync = <T extends {}>(App: (props: T) => JSX.Element) =>
  function WithURLSync(props: T) {
    const [searchState, setSearchState] = useState(
      qs.parse(typeof window !== 'undefined' ? window.location.search?.slice(1) ?? '' : ''),
    )
    const onPopState = ({ state }: PopStateEvent) => {
      setSearchState(state)
    }
    useEffect(() => {
      window.addEventListener('popstate', onPopState)
      return function cleanUp() {
        window.removeEventListener('popstate', onPopState)
      }
    }, [])

    const onSearchStateChange = useCallback((state: qs.ParsedQs) => {
      window.history.replaceState(state, '', searchStateToURL(state))
      setSearchState(state)
    }, [])

    return (
      <App
        {...props}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={searchStateToURL}
      />
    )
  }

export default withURLSync
