import React, { useState, useRef, useEffect, useCallback, ReactNode, useLayoutEffect } from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { RemoveScroll } from 'react-remove-scroll'
import {
  toCssPrefix,
  breakpoints,
  Anchor,
  DefaultTheme,
  ZIndex,
  SearchInput,
  Tooltip,
  useLocation,
  getSelectedSessionCity,
  saveSelectedSessionMarket,
  useShoppingCart,
  useAuth,
  getStoredZone,
  useResolution,
  hexToRGBA,
  DistributionCenter,
} from '@ecommerce/shared'
import { useUI } from '@ecommerce/shared/src/context/UI/ui.context'
import { navigate } from 'gatsby'
import { Icon } from '../Icon/Icon'
import { NavBarMenuProps, NavBarMenuDesktop } from './NavBarMenu'
import MenuMobile from '../MenuMobile/MenuMobile'
import { FlatLocationProductCategory } from '../../types/PgPages'
import MenuDesktop from '../MenuDesktop'
import LocationSelectorTooltip from './Tooltips/LocationSelectorTooltip'
import secrets from '../../config/secrets'
import { sendCartDataEvent } from '../../utils/events'
import { SearchBox } from './SearchBox'

const { cssPrefix, toPrefix, toPrefixArray } = toCssPrefix('MainNavBar__')

const fallbackColor = (color?: string) => {
  if (color?.includes('linear')) {
    return color?.split(' ')[color?.split(' ').length - 2]
  }
  return color
}

const IconNumber = styled.div<{ showNumber: boolean }>`
  min-width: 28px;
  border-radius: 4px;
  position: absolute;
  display: ${(props) => (props.showNumber ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  top: 14px;
  padding-left: 14px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: ${({ theme }) => fallbackColor(theme.colors.background.layout)};
  @media (${breakpoints.desktop.min}) {
    top: 19px;
    padding-left: 13px;
  }
`

const leftRightSectionsWidthDesktop = '20%'
const leftRightSectionsMaxWidthDesktop = '322px'
const leftRightSectionsMinWidthDesktop = '221px'
const textColor = ({ theme }: { theme: DefaultTheme }) => `color: ${theme.colors.iconsColor};`

const defaultTabs: NavBarMenuProps['tabs'] = [
  { iconId: 'grid', label: 'Categorías' },
  { iconId: 'Promociones', label: 'Promociones' },
  { iconId: 'glass-cup', label: 'mi Bar' },
]

interface WrapperProps {
  hasSearchText?: boolean
  hasSearchOpen?: boolean
  hasQuantity?: boolean
  widthLabelLocation?: number
}

const animationsTime = 0.15

export const MobileMenuSpacer = styled.div`
  margin-top: 123px;
  @media (${breakpoints.desktop.min}) {
    margin-top: 0;
    display: none;
  }
`

export const MobileLocationWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 0.5px solid ${({ theme }) => hexToRGBA(theme.colors.black40, 0.25)};
  svg {
    margin-inline-start: 27px;
  }
  p {
    height: inherit;
    margin-inline-start: 11px;
    margin-block: 0;
    align-items: center;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: 0.1px;
  }
  p > span {
    color: ${({ theme }) => theme.colors.red};
    font-weight: bold;
  }
  > span {
    height: inherit;
    margin-inline-start: 16px;
    font-size: 13px;
    line-height: 32px;
    letter-spacing: -0.06px;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.platinum80};
    font-weight: bold;
  }
`

const Wrapper = styled.div<WrapperProps>`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${ZIndex.high};

  .${cssPrefix} {
    &nav {
      position: absolute;
      width: 100vw;
      height: 60px;
      background: ${({ theme }) => theme.colors.background.layout || theme.colors.brand.default};
      display: flex;
      padding: 8px 24px 8px 16px;
      z-index: ${ZIndex.high};
      ${textColor}
    }

    &left {
      width: 50%;
      display: flex;
      align-items: center;

      &-container {
        display: flex;
        align-items: center;
      }

      &-logo {
        svg {
          width: 112px;
          height: 36px;
          fill: ${({ theme }) => theme.colors.brand.default};
        }
      }
    }

    &burguer-menu {
      margin-right: 16px;
    }

    &center {
      display: none;
      flex-grow: 1;
    }

    &right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;

      &-icon {
        width: 30px;
        height: 30px;

        &-anchor {
          ${textColor}
        }
      }
    }

    &tooltip {
      position: absolute;
      right: 72px;
      top: 56px;
    }

    &initials {
      color: ${({ theme }) => theme.colors.background.layout};
      background: ${({ theme }) => theme.colors.white};
      font-size: 20px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      font-weight: bold;
      display: inline-block;
      padding-top: 2px;
      margin-top: -3px;

      span {
        text-transform: uppercase;
      }
    }

    &right-icon {
      display: inline-block;
    }

    &cart-icon {
      width: ${({ hasQuantity }) => (hasQuantity ? '36px' : '30px')};
      height: ${({ hasQuantity }) => (hasQuantity ? '36px' : '30px')};
      padding-bottom: ${({ hasQuantity }) => (hasQuantity ? '1px' : 0)};
    }

    &location-selector {
      display: none;
      &-label {
        display: none;
      }
    }

    &menu {
      position: absolute;
      top: 123px;
      z-index: ${ZIndex.low};
    }

    &search {
      &-overlay {
        position: absolute;
        height: 200vh;
        overflow: hidden;
        width: 100vw;
        left: 0;
        top: 0;
        background: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
        z-index: ${ZIndex.low};
      }

      &-container {
        position: absolute;
        background: ${({ theme }) => theme.colors.background.layout || theme.colors.brand.default};
        top: 60px;
        left: 0;
        height: 63px;
        width: 100vw;
        z-index: ${ZIndex.medium};
        padding: 0 16px 0 16px;
        display: flex;
      }

      &-input {
        height: 36px;
        width: 100%;

        &-element {
          transition: padding-left ${animationsTime}s;
          ${({ hasSearchText }) => (hasSearchText ? 'padding-left: 11px' : '')};
        }
      }
    }
  }

  @media (${breakpoints.tabletPortrait.min}) {
    &location-tooltip {
      right: 94px;
    }
  }

  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &initials {
        font-size: 20px;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &tooltip {
        right: 94px;
      }

      &mobile-only {
        display: none;
      }

      &nav {
        height: 72px;
        padding: 0 60px;
      }

      &left {
        width: 18%;
        margin-right: 15px;

        &-logo {
          svg {
            width: 160px;
            height: 50px;
          }
        }
      }

      &burguer-menu {
        display: none;
      }

      &center {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &menu-desktop {
        width: 50%;
        transition: opacity ${animationsTime}s;
        margin-left: 15px;
        ${({ hasSearchOpen }) =>
          hasSearchOpen
            ? css`
                opacity: 0;
                cursor-events: none;
              `
            : ''}
      }

      &search-input-desktop {
        &-container {
          width: 191px;
        }

        height: 48px;
        transition: all 150ms ease-in-out;
        ${({ hasSearchOpen }) => {
          if (!hasSearchOpen) return 'width: 100%;'
          return css`
            width: calc(250% + 20px);
            margin-left: calc(-150% - 20px);
          `
        }}
      }

      &right {
        width: ${leftRightSectionsWidthDesktop};
        min-width: ${leftRightSectionsMinWidthDesktop};
        max-width: ${leftRightSectionsMaxWidthDesktop};
        gap: 14px;

        &-icon {
          display: inline-block;
          width: 27.5px;
          height: 27.5px;

          &-search {
            display: none;
          }
        }
      }

      &cart-icon {
        width: ${({ hasQuantity }) => (hasQuantity ? '36px' : '27.5px')};
        height: ${({ hasQuantity }) => (hasQuantity ? '36px' : '27.5px')};
        padding-bottom: ${({ hasQuantity }) => (hasQuantity ? '4px' : 0)};
      }

      &location-selector {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 13px;

        &-icon {
          margin-top: -4px;
          margin-right: 5px;
          width: 30px;
          height: 30px;
        }

        &-label {
          display: block;
          font-size: 13px;
        }
      }
    }
  }

  @media (${breakpoints.desktop.medium}) {
    .${cssPrefix} {
      &menu-desktop {
        margin-left: 0;
        width: 330px;
      }

      &search-input-desktop {
        &-container {
          width: 327px;
        }

        ${({ hasSearchOpen }) => {
          if (!hasSearchOpen) return 'width: 100%;'
          return css`
            width: calc(200% + 20px);
            margin-left: calc(-100% - 20px);
          `
        }}
      }
    }
  }

  @media (${breakpoints.desktopWide.min}) {
    .${cssPrefix} {
      &search-input-desktop {
        &-container {
          width: 388px;
        }

        ${({ hasSearchOpen }) => {
          if (!hasSearchOpen) return 'width: 100%;'
          return css`
            width: calc(200% + 20px);
            margin-left: calc(-100% - 20px);
          `
        }}
      }
    }
  }

  @media (${breakpoints.desktopHD.min}) {
    .${cssPrefix} {
      &search-input-desktop {
        &-container {
          width: 530px;
        }

        ${({ hasSearchOpen }) => {
          if (!hasSearchOpen) return 'width: 100%;'
          return css`
            width: calc(180% + 20px);
            margin-left: calc(-80% - 20px);
          `
        }}
      }
    }
  }
`

export enum NavbarType {
  MAIN = 'main',
  SEARCH = 'search',
}

type AnchorClickEvent = React.MouseEvent<HTMLButtonElement>

interface LogoProps {
  url?: string
  onClick?: (e: AnchorClickEvent) => void
  className?: string
  children?: ReactNode
}

const Logo = ({ onClick, className, children }: LogoProps) => {
  return (
    <Anchor onClick={onClick} className={className}>
      {children}
    </Anchor>
  )
}

export interface MainNavbarProps {
  type?: NavbarType
  logoUrl?: string
  onLogoClick?: (e: AnchorClickEvent) => void
  onUserClick?: (e: AnchorClickEvent) => void
  onCartClick?: (e: AnchorClickEvent) => void
  onLocationSelectorClick?: (e: AnchorClickEvent | React.MouseEvent<HTMLDivElement>) => void
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSearchKeydown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onSearchClear?: () => void
  onSignOut?: () => void
  userName?: string
  defaultSearchValue?: string
  tabs?: NavBarMenuProps['tabs']
  zoneName?: string
  cartItemsQuantity?: number
  leftSection?: React.ReactNode
  centerSection?: React.ReactNode
  rightSection?: React.ReactNode
  useAlgoliaSearch?: boolean
  disableControls?: boolean
  imgUrl?: string
  onLocationClick?: () => void
  onNavigationClick?: (slug: string) => void
  categories?: FlatLocationProductCategory[]
  currentDistributionCenter?: DistributionCenter
}

export function MainNavbar({
  onLogoClick,
  onSearchClear,
  defaultSearchValue,
  onSearchChange,
  onSearchKeydown,
  onUserClick,
  onLocationSelectorClick,
  tabs,
  zoneName,
  cartItemsQuantity,
  leftSection,
  centerSection,
  rightSection,
  useAlgoliaSearch,
  disableControls,
  userName,
  imgUrl,
  onNavigationClick,
  categories,
  onSignOut,
  currentDistributionCenter,
}: MainNavbarProps) {
  const { openDrawerCart } = useUI()
  const isInCartPage = typeof window !== 'undefined' ? window.location.pathname.includes('/cart') : null

  const [searchIsOpen, setSearchIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState(defaultSearchValue ?? '')
  const [tooltipIsActive, setTooltipIsActive] = useState(!!userName)
  const [tooltipLocationIsActive, setTooltipLocationIsActive] = useState(false)
  const [widthLabelLocation, setWidthLabelLocation] = useState<number | undefined>(0)
  const [showMenu, setShowMenu] = useState(false)
  const refLocationSelector = useRef<HTMLButtonElement | null>(null)

  const currentZone = getStoredZone()
  const selectedSessionCity = getSelectedSessionCity()

  const { isBolivia } = useLocation()
  const { getEntityInfo } = useAuth()
  const { isMobile, isTablet } = useResolution()
  const { getConnectifCart } = useShoppingCart()

  useEffect(() => {
    if (!selectedSessionCity) {
      setTooltipLocationIsActive(true)
      setTimeout(() => {
        setTooltipLocationIsActive(false)
        saveSelectedSessionMarket()
      }, secrets.TIME_TOOLTIP_LOCATION_IS_ACTIVE)
    } else {
      setTooltipLocationIsActive(false)
    }
  }, [])

  useEffect(() => {
    if (!tooltipIsActive && userName) {
      setTooltipIsActive(true)
      setTimeout(() => setTooltipIsActive(false), 5000)
    } else setTooltipIsActive(false)
  }, [userName])

  const ref = useRef<HTMLInputElement | null>(null)

  const onSearchClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (disableControls) return
    setSearchIsOpen(!searchIsOpen)
  }

  useEffect(() => {
    if (ref?.current?.focus) ref.current.focus()
  }, [searchIsOpen])

  useLayoutEffect(() => {
    setWidthLabelLocation(refLocationSelector?.current?.offsetWidth)
  }, [])

  useEffect(() => {
    const cart = getConnectifCart()
    const entityInfo = getEntityInfo()

    sendCartDataEvent(cart, entityInfo)
  }, [cartItemsQuantity])

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disableControls) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
    setSearchValue(e.target.value)
    if (onSearchChange) onSearchChange(e)
  }

  const SearchIcon = () => {
    if (!searchValue) return <Icon iconId="search" onClick={onSearchClick} cursor="pointer" fillColor="black80" />
    return <span />
  }

  const desktopRightIcons = [
    <Anchor
      ref={refLocationSelector}
      key="location-selector"
      data-testid="location-selector"
      className={toPrefixArray(['right-icon-anchor', 'location-selector'])}
      onClick={(e) => {
        if (disableControls || !onLocationSelectorClick) return
        onLocationSelectorClick(e)
      }}
      style={disableControls ? { visibility: 'hidden' } : {}}
    >
      <Icon iconId="location" size={32} className={toPrefix('location-selector-icon')} />
      <span className={toPrefix('location-selector-label')}>{zoneName ?? ''}</span>
    </Anchor>,
  ]

  const getCartIcon = useCallback(() => {
    if (!cartItemsQuantity) return 'cart_0'
    if (cartItemsQuantity === 1) return 'cart_2'
    if (cartItemsQuantity > 1 && cartItemsQuantity < 99) return 'cart_2'
    if (cartItemsQuantity === 99) return 'cart_3'
    if (cartItemsQuantity > 99) return 'cart_4'
    return 'cart_0'
  }, [cartItemsQuantity])

  const commonRightIcons = [
    <Anchor
      key="open-user-modal"
      data-testid="open-user-modal"
      className={toPrefix('right-icon-anchor')}
      onClick={(e) => {
        if (disableControls) {
          e.preventDefault()
          e.stopPropagation()
          return
        }
        setSearchIsOpen(false)
        if (onUserClick) onUserClick(e)
      }}
    >
      {userName ? (
        <span className={toPrefix('initials')}>
          <span>{userName.slice(0, 1).toUpperCase()}</span>
        </span>
      ) : (
        <Icon iconId="user" className={toPrefix('right-icon')} />
      )}
    </Anchor>,
    <>
      {tooltipIsActive && (
        <Tooltip className={toPrefix('tooltip')} tipPosition="right">
          {`¡Hola ${userName}!`}
        </Tooltip>
      )}
    </>,
    <>
      {tooltipLocationIsActive && (
        <LocationSelectorTooltip
          widthLabelLocation={widthLabelLocation}
          setTooltipLocationIsActive={setTooltipLocationIsActive}
          onLocationSelectorClick={onLocationSelectorClick}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      )}
    </>,
    <Anchor
      data-testid="open-cart-modal"
      className={toPrefix('right-icon-anchor')}
      onClick={(e) => {
        if (disableControls) {
          e.preventDefault()
          e.stopPropagation()
          return
        }
        setSearchIsOpen(false)
        if (!isInCartPage && isMobile) {
          navigate('/cart')
        }
        if (!isInCartPage && !isMobile && openDrawerCart) {
          openDrawerCart()
        }
      }}
    >
      <Icon iconId={getCartIcon()} className={toPrefix('cart-icon')} />
      <IconNumber showNumber={!!cartItemsQuantity && cartItemsQuantity < 99}>
        {!!cartItemsQuantity && cartItemsQuantity < 99 ? cartItemsQuantity : ''}
      </IconNumber>
    </Anchor>,
  ]

  const keyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (disableControls) {
        e.preventDefault()
        e.stopPropagation()
        return
      }
      e.stopPropagation()
      if (e.key === 'Escape') {
        e.currentTarget.blur()
        setSearchIsOpen(false)
      }
      if (e.key === 'Enter' && useAlgoliaSearch) {
        e.currentTarget.blur()
        setSearchIsOpen(false)
      }
      if (onSearchKeydown) onSearchKeydown(e)
    },
    [onSearchKeydown],
  )

  return (
    <>
      {showMenu && (
        <MenuMobile
          onClose={() => setShowMenu(false)}
          onBlur={() => setShowMenu(false)}
          logoUrl={imgUrl}
          onNavigationClick={onNavigationClick}
          categories={categories}
          onSignOut={onSignOut}
        />
      )}
      <Wrapper
        hasQuantity={!!cartItemsQuantity && cartItemsQuantity > 0}
        hasSearchText={!!searchValue}
        hasSearchOpen={searchIsOpen}
        data-testid="mainnav-bar"
        widthLabelLocation={widthLabelLocation}
      >
        <div className={toPrefix('nav')} data-testid="main-nav-bar">
          <div className={toPrefix('left')}>
            {leftSection ?? (
              <div className={toPrefix('left-container')}>
                <Icon
                  onClick={() => setShowMenu(!showMenu)}
                  className={toPrefix('burguer-menu')}
                  size="32"
                  iconId="menu"
                />
                <Logo
                  onClick={(e) => {
                    if (disableControls) {
                      e.preventDefault()
                      e.stopPropagation()
                      return
                    }
                    setSearchIsOpen(false)
                    if (onLogoClick) onLogoClick(e)
                  }}
                  className={toPrefix('left-logo')}
                >
                  <Icon iconId={isBolivia() ? 'logo-bo' : 'logo-cl'} />
                </Logo>
              </div>
            )}
          </div>
          <div className={toPrefix('center')}>
            {centerSection ?? (
              <>
                <NavBarMenuDesktop tabs={tabs ?? defaultTabs} className={toPrefix('menu-desktop')} />
                <div className={toPrefix('search-input-desktop-container')}>
                  <SearchBox
                    className={toPrefix('search-input-desktop')}
                    inputClassName={toPrefix('search-input-element')}
                    onClear={() => {
                      if (disableControls) return
                      if (onSearchClear) onSearchClear()
                      setSearchValue('')
                    }}
                    onChange={onSearchInputChange}
                    searchIcon={SearchIcon}
                    value={searchValue}
                    onKeydown={keyDownHandler}
                    onFocus={() => {
                      if (disableControls) return
                      setSearchIsOpen(true)
                    }}
                    onBlur={() => {
                      if (disableControls) return
                      setSearchIsOpen(false)
                    }}
                    currentDistributionCenter={currentDistributionCenter}
                  />
                </div>
              </>
            )}
          </div>
          <div className={toPrefix('right')}>{rightSection ?? [...desktopRightIcons, ...commonRightIcons]}</div>
        </div>
        <div className={toPrefix('mobile-only')}>
          <div className={toPrefix('search-container')}>
            <SearchInput
              className={toPrefix('search-input')}
              inputClassName={toPrefix('search-input-element')}
              placeholder="Busca algún producto"
              reset={() => {
                if (disableControls) return
                if (onSearchClear) onSearchClear()
                setSearchValue('')
              }}
              onChange={onSearchInputChange}
              CustomSearchIcon={SearchIcon}
              CustomCloseIcon={() => <Icon iconId="close" />}
              value={searchValue}
              hasText={!!searchValue}
              reference={ref}
              keyDownHandler={keyDownHandler}
            />
          </div>
        </div>
        <AnimatePresence>
          {searchIsOpen && (
            <RemoveScroll>
              <motion.div
                className={toPrefix('search-overlay')}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: animationsTime }}
                onClick={() => {
                  if (disableControls) return
                  setSearchIsOpen(false)
                }}
              />
            </RemoveScroll>
          )}
        </AnimatePresence>
      </Wrapper>
      <MobileMenuSpacer className="navbar-menu-spacer" />
      {categories && <MenuDesktop categories={categories} />}
      {(isMobile || isTablet) && (
        <MobileLocationWrapper onClick={(event) => onLocationSelectorClick && onLocationSelectorClick(event)}>
          <Icon iconId="location" size="22" fillColor="red" />
          <p>
            Entrega en
            <span>
              &nbsp;
              {currentZone?.name}
            </span>
          </p>
          <span>Cambiar</span>
        </MobileLocationWrapper>
      )}
    </>
  )
}
