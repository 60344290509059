import styled from 'styled-components'

export const ContainerEmptyCart = styled.div`
  width: 100%;

  .location-selector {
    background: ${({ theme }) => theme.colors.white};
  }

  .button-show-catalog {
    width: 100%;
  }
`

export const EmptyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-inline: 57px;
`

export const BoxEmptyIcon = styled.div`
  background-color: transparent;
  outline: transparent none;
  margin-block-start: 80px;
  margin-block-end: 16px;

  &:focus {
    border: none;
    outline: none 0;
    -moz-outline-style: none;
  }

  svg {
    width: 95px;
    height: 95px;
    fill: ${(props) => props.theme.colors.red};
    object-fit: contain;
  }
`

export const EmptyTitle = styled.span`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  font-weight: bold;
  margin-block-end: 12px;
`

export const EmptySubtitle = styled.span`
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.15px;
  margin-block-end: 16px;
`
