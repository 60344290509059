import styled from 'styled-components'
import { breakpoints, hexToRGBA, ZIndex } from '@ecommerce/shared'

export const Wrapper = styled.div`
  background: ${(props) => hexToRGBA(props.theme.colors.grey80, 0.75)};
  position: fixed;
  bottom: 0;
  left: 0;
  min-height: 160px;
  z-index: ${ZIndex.medium};

  @media (${breakpoints.tabletPortrait.min}) {
    width: 100%;
    left: revert;
    right: 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  border-radius: 4px;
  padding-inline: 16px;
  padding-block: 16px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  box-shadow: ${(props) => props.theme.boxShadow.lvlOne};

  @media screen and (${breakpoints.tabletPortrait.min}) {
    padding: 12px 40px 32px 40px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};
  @media screen and (${breakpoints.tabletPortrait.min}) {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.254118px;
  }
`

export const ShowMore = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  > span {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.06px;
    color: ${({ theme }) => theme.colors.red};
  }
  :hover {
    cursor: pointer;
  }
`

export const Alert = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 8px;
  height: 32px;
  background: ${({ theme }) => hexToRGBA(theme.colors.red20, 0.1)};
  border-radius: 4px;
  border-left: 4px solid ${({ theme }) => theme.colors.error};

  .icon {
    margin-left: 4px;
  }

  .message {
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.error};
    font-weight: normal;
    > span {
      font-weight: bold;
    }
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.platinum80};
  div:nth-child(2) {
    color: ${({ theme }) => theme.colors.green};
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`

export const CommonContainerPrices = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Subtotal = styled(CommonContainerPrices)`
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.24px;
  color: ${({ theme }) => theme.colors.platinum80};

  @media screen and (${breakpoints.tabletPortrait.min}) {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.254118px;
  }

  .wrapper-loader {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-inline-end: 16px;
  }
`

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 8px;
`
