import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  getOrderId,
  getStoredDistributionCenter,
  hasScrollbarStyles,
  ProductCart as CartItem,
  sendRemoveProductToGTM,
  useCart,
  useLocation,
  useResolution,
  useShoppingCart,
  FormattedProduct,
  slugify,
} from '@ecommerce/shared'
import { motion } from 'framer-motion'
import EmptyCart from './EmptyCart'

import ProductCart from './ProductCart'
import secrets from '../../config/secrets'
import GiftProducts from './GiftProducts'
import OutOfStockProducts from './OutOfStockProducts'
import { IconTrash } from '../Icons'
import Summary from './Summary'
import { WrapperDetailCart } from './ShoppingCart.styled'

interface ItemProps {
  cart?: boolean
}

const Items = styled.div<ItemProps>`
  width: 100%;
  position: initial;
  padding-block-start: 72px;
  overflow-y: auto;
  .location-selector {
    background: ${({ theme }) => theme.colors.white};
    border: none;
  }
  ${hasScrollbarStyles}
`

const ContainerWithProducts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
  .summary {
    width: 100%;
  }
`

const ButtonTrash = styled(motion.div)`
  background-color: transparent;
  outline: transparent none;

  &:focus {
    border: none;
    outline: none 0;
    -moz-outline-style: none;
  }

  svg {
    fill: ${({ theme }) => theme.colors.red};
    object-fit: contain;
  }
`

const TrashProduct = styled(motion.div)<{ showContent: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 4px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  > span {
    color: ${({ theme }) => theme.colors.red};
  }
  :hover {
    opacity: 0.6;
  }

  margin-bottom: ${({ showContent }) => (showContent ? '322px' : '232px')};
`
interface Props {
  className?: string
}

const ShoppingContent = ({ className }: Props) => {
  const isCartPage = window.location.pathname.includes('cart')

  const { isBolivia } = useLocation()
  const { isMobile } = useResolution()

  const [giftLineItems, setGiftLineItems] = useState<Array<{ quantity: number; sku: number }>>()
  const [outOfStockProducts, setOutOfStockProducts] = useState<CartItem[]>([])
  const [algoliaProducts, setAlgoliaProducts] = useState<FormattedProduct[]>([])

  const shoppingCart = useShoppingCart()
  const { setOpenEmptyCartAlert, showSummary } = useCart()

  const { state, removeProduct, revalidateOrder, getAlgoliaProducts } = shoppingCart
  const { byHash, globalTotal, promotions } = state

  const currentDistributionCenter = useMemo(() => getStoredDistributionCenter(), [getStoredDistributionCenter])
  const orderId = useMemo(() => getOrderId(), [getOrderId])

  const skus = Object.keys(byHash)
  const minSubtotal = Number(secrets.MIN_RAW_TOTAL)

  const isSubtotalValid = globalTotal >= minSubtotal

  const getAlgoliaProductsInShoppingCart = async (slugLocation: string) => {
    setAlgoliaProducts(await getAlgoliaProducts({ slugLocation }))
  }

  useEffect(() => {
    if (isCartPage && Object.values(byHash).length > 0) {
      revalidateOrder()
    }
  }, [isCartPage])

  useEffect(() => {
    setGiftLineItems(
      state?.giftLineItems?.map(({ sku, discountQuantity = 0 }) => ({
        sku: Number(sku),
        quantity: discountQuantity,
      })) || [],
    )
  }, [state.giftLineItems])

  useEffect(() => {
    if (!currentDistributionCenter) return
    const slugLocation = slugify(currentDistributionCenter.commerceLayer.stockLocation.name)

    getAlgoliaProductsInShoppingCart(slugLocation)
  }, [currentDistributionCenter, skus.length])

  const onDeleteOutOfStockProducts = () => {
    sendRemoveProductToGTM(outOfStockProducts, isBolivia())
    outOfStockProducts.forEach((p) => removeProduct(p), orderId)
    setOutOfStockProducts([])
  }

  return (
    <Items cart={isCartPage} className={className}>
      {skus.length === 0 && outOfStockProducts.length === 0 && currentDistributionCenter ? (
        <EmptyCart />
      ) : (
        <ContainerWithProducts>
          <OutOfStockProducts onDelete={onDeleteOutOfStockProducts} products={outOfStockProducts} isBO={isBolivia()} />

          <WrapperDetailCart>
            {skus.map((skuCode) => (
              <ProductCart
                presale={Number(skuCode) === secrets.JW_PRESALE_SKU}
                key={skuCode}
                product={byHash[skuCode]}
                promotion={promotions && promotions[skuCode]}
                returnabilityLabel={
                  algoliaProducts?.find((algoliaProduct) => Number(algoliaProduct.skuCode) === Number(skuCode))
                    ?.returnabilityLabel
                }
              />
            ))}
            {isSubtotalValid && currentDistributionCenter ? (
              <GiftProducts
                key={giftLineItems?.length}
                slugLocation={currentDistributionCenter?.slug}
                items={giftLineItems}
              />
            ) : null}
          </WrapperDetailCart>

          <TrashProduct
            data-testid="trash-cart"
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              setOpenEmptyCartAlert(true)
            }}
            showContent={showSummary}
          >
            <span className="shopping-cart-product-clear">Vaciar carro</span>
            <ButtonTrash>
              <IconTrash />
            </ButtonTrash>
          </TrashProduct>
          {isMobile && <Summary className="summary" isLoading={false} isDisabled={false} />}
        </ContainerWithProducts>
      )}
    </Items>
  )
}

export default ShoppingContent
