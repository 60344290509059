/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect, HTMLAttributes } from 'react'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import loadable from '@loadable/component'
import {
  ZIndex,
  useAuth,
  capitalize,
  useResolution,
  getAuth,
  getStoredMarket,
  useLocation,
  AuthFormType,
  AuthWidget,
  useShoppingCart,
  getStoredZone,
  hexToRGBA,
  DistributionCenter,
  getStoredDistributionCenter,
} from '@ecommerce/shared'
import styled from 'styled-components'
import { InstantSearch } from 'react-instantsearch-dom'
import algoliaSearch from 'algoliasearch/lite'
import { useUI } from '@ecommerce/shared/src/context/UI/ui.context'
import { Footer } from '../Footer'
import secrets from '../../config/secrets'
import { FlatLocationProductCategory, PgPageProps } from '../../types/PgPages'
import withURLSync from '../../utils/URLSync'
import { useUserLoginHandler } from '../../hooks/useUserLoginHandler'
import useSession from '../../hooks/useSession'
import useAuthWidget from '../../hooks/useAuthWidget'
import { MainNavbar } from '../NavBar/MainNavbar'
import { useMobileNavbarMenu } from '../../hooks/useMobileNavbarMenu'
import { MomentHeader } from '../NavBar/MomentHeader'
import ShoppingCart from '../ShoppingCart'
import MyAccountSidebar from '../MyAccountSidebar/MyAccountSidebar'
import LocationSelector from '../LocationSelector'
import { sendLoginEvent } from '../../utils/events'
import { useAppSelector } from '../../store'
import { selectSearchState } from '../../store/algolia/searchStateSlice'

const searchClient = algoliaSearch(secrets.ALGOLIA_APP_ID, secrets.ALGOLIA_API_KEY)

const loadableOptions = {
  ssr: false,
}

const AgeModal = loadable(() => import('../AgeDisclosureModal'), loadableOptions)
const ConfirmationAlert = loadable(() => import('../ConfirmationAlert'), loadableOptions)
const ModalBackground = loadable(() => import('@ecommerce/shared'), {
  resolveComponent: (components) => components.ModalBackground,
  ...loadableOptions,
})

interface StyleProps {
  customNavbar: boolean
  categories?: FlatLocationProductCategory[]
}

const Container = styled.div<StyleProps>`
  position: relative;
  margin: ${({ customNavbar, categories }) => (customNavbar || !categories ? '72px' : '114px')} 0 0;
  width: 100%;
  z-index: ${ZIndex.default};
`

const ContainerOpacity = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${({ theme }) => hexToRGBA(theme.colors.black60, 0.5)};
  z-index: 4;
  opacity: 0.5;
`

const NavBarWrapper = styled.div`
  top: 0;
  width: 100vw;
  z-index: ${ZIndex.medium};
`

export interface CustomNavbarProps extends HTMLAttributes<HTMLDivElement> {
  pathName?: string
  onOpenCart?: () => void
  onUserClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onLocationClick?: () => void
  currentCity?: string
}

interface Props {
  children: React.ReactNode
  title: string
  description?: string
  keywords?: string[]
  id?: string
  pathName?: string
  location?: PgPageProps['location']
  navbar?: (props: CustomNavbarProps) => React.ReactNode
  createURL?: (...args: Record<string, unknown>[]) => string
  navbarType?: string
  navbarTitlte?: string
  categories?: FlatLocationProductCategory[]
  useAlgoliaSearch?: boolean
  defaultSearchValue?: string
  disableControls?: boolean
  customNavbar?: React.ReactNode
  currentDistributionCenter?: DistributionCenter
}

export const imgUrlCL =
  'https://images.ctfassets.net/16npdkkoi5mj/UQAY5ote3JybB3dWTDfKp/743c2ca9fdeb79c72374cecb6bf557a8/Logo_MCC_Chilea_2.svg'

export const imgUrlBO =
  'https://images.ctfassets.net/5czn5snkqxg9/6My5qEokCQ4vvhFFU6m4W0/c2a0b1dc859f1acacb58cf5a8af769af/Logo_Micoca-cola_Bolivia.svg'

const Layout = (props: Props) => {
  const isCartPage = props.pathName?.includes('cart')

  const {
    children,
    categories,
    description,
    keywords,
    title,
    id,
    pathName = '',
    location,
    currentDistributionCenter,
  } = props

  const distributionCenter = currentDistributionCenter || getStoredDistributionCenter() || undefined

  const storedMarket = getStoredMarket()
  const storedZone = getStoredZone()

  const currentMarket = storedMarket
  const currentZone = storedZone

  const { isDesktop, isMobile, isTablet } = useResolution()

  const isAuth = getAuth()
  const { resetAppState } = useSession()
  const { UserLoginHandlerAlertComponent, userLoginCartHandler } = useUserLoginHandler()

  const {
    state: { firstName, lastName },
    getEntityInfo,
  } = useAuth()

  const { isBolivia } = useLocation()
  const { displayCartDrawer, displaySuggestedProducts } = useUI()
  const isBO = isBolivia()

  const { state: cartState, getConnectifCart, getProducts } = useShoppingCart()

  const [isOpenMyAccount, setIsOpenMyAccount] = useState(false)
  const [isOpenSignOutModal, setIsOpenSignOutModal] = useState(false)
  const [isOpenLocation, setIsOpenLocation] = useState(false)

  const { showWidget, closeWidget, state: authWidgetState, getProps } = useAuthWidget()

  const { globalQuantity } = cartState

  function onCloseModal() {
    closeWidget()
  }

  const onFinishedSignIn = async () => {
    const cart = getConnectifCart()
    const entityInfo = getEntityInfo()
    sendLoginEvent(cart, entityInfo)
    await navigate(`${window.location.pathname}?show_greeting=true`)
    onCloseModal()
  }

  const authFormsProps = {
    signin: {
      userLoginCartHandler,
      title: 'Iniciar Sesión',
      onFinished: () => onFinishedSignIn(),
      disableTitle: true,
    },
    guest: {
      title: 'Continuar como invitado',
      onFinished: () => onCloseModal(),
    },
    signup: {
      title: 'Crear Cuenta',
      onFinished: () => onCloseModal(),
    },
  }

  function showAuthWidget(type: AuthFormType) {
    showWidget(type)
  }
  const onSignOut = async () => {
    setIsOpenSignOutModal(false)
    setIsOpenMyAccount(false)

    await resetAppState(`/`)
  }

  useEffect(() => {
    const params = window && window.location ? new URLSearchParams(window.location.search) : null
    const showLocation = params ? params.get('show_location') : null
    const zoneQuery = params?.get('city')
    const askUrl = params ? !!params.get('ask_auth') : null
    const createAccountPram = params ? !!params.get('create_account') : null

    if (zoneQuery) {
      setIsOpenLocation(false)
      return
    }

    if ((!currentZone && !currentMarket && !distributionCenter) || showLocation) {
      setIsOpenLocation(true)
    }

    if (askUrl) return showAuthWidget(AuthFormType.SIGNIN)

    if (createAccountPram) return showAuthWidget(AuthFormType.SIGNUP)
  }, [])

  const navbarMenu = useMobileNavbarMenu({
    isMoment: props.navbarType === 'moment' && !!props.navbarTitlte && !isDesktop,
    disableControls: props.disableControls,
  })?.filter((tab) => tab !== null)

  const searchState = useAppSelector(selectSearchState)

  return (
    <>
      {authWidgetState.showWidget && authWidgetState.type ? (
        <AuthWidget
          showWidget={showAuthWidget}
          closeWidget={closeWidget}
          title={authFormsProps[authWidgetState.type].title}
          formSettings={getProps(authWidgetState.type, authFormsProps)}
          onClose={closeWidget}
        />
      ) : null}
      <AgeModal hideModal={isBO} />
      <InstantSearch
        indexName={secrets.ALGOLIA_INDEX_NAME}
        searchClient={searchClient}
        searchState={searchState}
        createURL={props.createURL}
      >
        <Container id={id} customNavbar={!!props.customNavbar} categories={props.categories}>
          {isMobile && displaySuggestedProducts && <ContainerOpacity />}
          {props.customNavbar && <NavBarWrapper>{props.customNavbar}</NavBarWrapper>}
          {!props.customNavbar &&
            (props.navbar ? (
              <NavBarWrapper>
                {props.navbar({
                  pathName,
                  onUserClick: () => {
                    if (!isAuth) {
                      if (authWidgetState.showWidget) return closeWidget()
                      return showAuthWidget(AuthFormType.SIGNIN)
                    }
                    setIsOpenMyAccount(!isOpenMyAccount)
                  },
                  onLocationClick: () => setIsOpenLocation(true),
                  currentCity: currentMarket ? capitalize(currentMarket.name) : '',
                })}
              </NavBarWrapper>
            ) : (
              <MainNavbar
                currentDistributionCenter={distributionCenter}
                logoUrl={
                  isBO
                    ? 'https://images.ctfassets.net/5czn5snkqxg9/6PESRN9i2N3aVUjITRalJS/786ba991d56a889d19c0379d7e051bdc/logo.svg'
                    : 'https://images.ctfassets.net/16npdkkoi5mj/7E46MY9iDUr4A5RkUAwsq5/8dd893fc8bf06d7c7bf048751cc74c35/160x50mcc__1_.svg'
                }
                imgUrl={isBO ? imgUrlBO : imgUrlCL}
                categories={categories}
                onLocationClick={() => setIsOpenLocation(true)}
                onNavigationClick={(slug) => navigate(`/${slug}`)}
                disableControls={props.disableControls}
                defaultSearchValue={props.defaultSearchValue}
                onSearchKeydown={(e) => {
                  e.stopPropagation()
                  closeWidget()
                  const query = e.currentTarget.value ?? (e.target as HTMLInputElement).value
                  if (e.key === 'Enter') navigate(`/search?query=${encodeURI(query)}`, { state: { query } })
                }}
                onUserClick={() => {
                  if (!isAuth) {
                    if (authWidgetState.showWidget) return closeWidget()
                    return showAuthWidget(AuthFormType.SIGNIN)
                  }
                  setIsOpenMyAccount(!isOpenMyAccount)
                }}
                onCartClick={() => {
                  if (isMobile) {
                    navigate('/cart')
                    return
                  }
                  closeWidget()
                  setIsOpenMyAccount(false)
                }}
                onLogoClick={() => {
                  navigate(`/`)
                }}
                onLocationSelectorClick={() => {
                  setIsOpenLocation(true)
                }}
                tabs={navbarMenu}
                zoneName={currentZone?.name}
                cartItemsQuantity={globalQuantity}
                leftSection={
                  props.navbarTitlte && props.navbarType === 'moment' ? (
                    <MomentHeader>
                      <span>{props.navbarTitlte}</span>
                    </MomentHeader>
                  ) : undefined
                }
                useAlgoliaSearch={props.useAlgoliaSearch}
                userName={firstName ?? undefined}
                onSignOut={() => setIsOpenSignOutModal(true)}
              />
            ))}
          <Helmet>
            <title>{title}</title>
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords.join(', ')} />}
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            {typeof window !== 'undefined' && (
              <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`} />
            )}
          </Helmet>
          {children}
          {isCartPage && (isMobile || isTablet) ? null : <Footer />}
          {isCartPage && (isMobile || isTablet) && getProducts().length === 0 && <Footer />}
        </Container>
        {displayCartDrawer && distributionCenter && (
          <ModalBackground>
            <ShoppingCart />
          </ModalBackground>
        )}
      </InstantSearch>
      {isOpenMyAccount && isAuth && (
        <MyAccountSidebar
          isRight
          customerName={`${capitalize(firstName)} ${capitalize(lastName)}`}
          logoUrl={isBO ? imgUrlBO : imgUrlCL}
          onClose={() => setIsOpenMyAccount(false)}
          onBlur={() => setIsOpenMyAccount(false)}
          onSignOut={() => setIsOpenSignOutModal(true)}
          onAccountClick={() => navigate(currentMarket && currentMarket.slug ? `/my-account` : '/')}
          onLocationClick={() => setIsOpenLocation(true)}
          onNavigationClick={(slug) => navigate(`/${slug}`)}
          currentCity={currentMarket ? capitalize(currentMarket.name) : ''}
        />
      )}
      {isOpenSignOutModal && isAuth && (
        <ConfirmationAlert
          onClose={() => setIsOpenSignOutModal(false)}
          onBlur={() => setIsOpenSignOutModal(false)}
          onConfirm={onSignOut}
          confirmButtonText="Cerrar sesión"
          text="¿Estás seguro de querer cerrar tu sesión?"
        />
      )}
      {isOpenLocation && <LocationSelector onClose={() => setIsOpenLocation(false)} requireConfirmation />}
      <UserLoginHandlerAlertComponent onFinished={onFinishedSignIn} />
    </>
  )
}

export default withURLSync<Props>(Layout)
