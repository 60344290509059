import React, { HTMLAttributes, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { useLocation } from '@ecommerce/shared'
import { Sidebar } from '../Sidebar'
import { MenuTile, MenuTileProps } from '../Sidebar/MenuTile'
import { menuItems } from './menuItems'

const cssPrefix = `MyAccountSidebar__`

const spacerStyles = css`
  height: 0;
  width: 0;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  .${cssPrefix} {
    &spacer-1 {
      ${spacerStyles}
      margin-top: 24px;
    }
    &name-container {
      display: flex;
      flex-direction: column;
      padding-left: 19px;
      &-customer-name {
        font-size: 18px;
        font-weight: bold;
      }
      &-account-link {
        margin-top: 5px;
        color: ${(props) => props.theme.colors.red};
        font-size: 15px;
        font-weight: bold;
        cursor: pointer;
        text-align: left;
      }
    }
    &spacer-2 {
      ${spacerStyles}
      margin-top: 30px;
    }
    &menu {
      padding-left: 19px;
    }
    &spacer-3 {
      ${spacerStyles}
      margin-top: 65px;
    }
    &help-contact {
      display: flex;
      justify-content: center;
      letter-spacing: -0.15px;
      font-size: 15px;
      text-align: center;
      font-size: 16px;
      line-height: 1.44;
      letter-spacing: -0.2px;
    }
  }
`

const addPrefix = (className: string) => `${cssPrefix}${className}`

export interface MyAccountSidebarProps {
  onClose: () => void
  onSignOut: () => void
  onBlur?: () => void
  onAccountClick?: () => void
  onLocationClick?: () => void
  onNavigationClick?: (slug: string) => void
  logoUrl?: string
  customerName?: string
  currentCity?: string
  isRight?: boolean
}

const MyAccountSidebar = (props: MyAccountSidebarProps & HTMLAttributes<HTMLDivElement>) => {
  const { isBolivia } = useLocation()

  const handleTileClick = (item: MenuTileProps) => {
    if (item.actionType === 'signout') {
      props.onSignOut()
    } else if (item.actionType === 'location' && props.onLocationClick) {
      props.onLocationClick()
    } else if (item.actionType === 'navigation' && item.slug && props.onNavigationClick) {
      props.onNavigationClick(item.slug)
    }
  }

  const formatMenuItemText = (item: MenuTileProps) => {
    if (item.actionType && item.actionType === 'location' && item.text.includes('$location')) {
      const arr = item.text.split('$location')
      return arr.join(props.currentCity || '')
    }
    return item.text
  }

  return (
    <Sidebar isRight={props.isRight} onClose={props.onClose} onBlur={props.onBlur} logoUrl={props.logoUrl}>
      <Wrapper>
        <div className={addPrefix('spacer-1')} />
        <div className={addPrefix('name-container')}>
          <span className={addPrefix('name-container-customer-name')}>{props.customerName}</span>
        </div>
        <div className={addPrefix('spacer-2')} />
        <div className={addPrefix('menu')}>
          {menuItems.map((item) => {
            if (!isBolivia() && item.slug === 'mis-facturas') {
              return null
            }
            return (
              <MenuTile
                key={item.text}
                iconId={item.iconId}
                text={formatMenuItemText(item)}
                onClick={() => handleTileClick(item)}
                data-test={item.dataTest || ''}
              />
            )
          })}
        </div>
      </Wrapper>
    </Sidebar>
  )
}

export default MyAccountSidebar
