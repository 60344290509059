import React, { useRef, useCallback } from 'react'
import { SearchInput, debounce, useShoppingCart, useAuth, DistributionCenter } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'
import { sendSearchEvent } from '../../utils/events'
import { useAppDispatch } from '../../store'
import { resetRefinementLists, setQuery } from '../../store/algolia/searchStateSlice'

export interface SearchBoxProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeydown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onClear?: () => void
  value?: string
  className?: string
  inputClassName?: string
  searchIcon?: () => JSX.Element
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent) => void
  refine?: (e: string) => void
  currentRefinement?: string
  refineOnEnter?: boolean
  currentDistributionCenter?: DistributionCenter
}

export function SearchBox({
  onFocus,
  onBlur,
  onChange,
  onKeydown,
  onClear,
  value,
  className,
  inputClassName,
  searchIcon,
  refine,
  refineOnEnter,
  currentDistributionCenter,
}: SearchBoxProps) {
  const dispatch = useAppDispatch()

  const clearInput = useCallback(() => {
    if (onClear) onClear()
    if (currentDistributionCenter) {
      dispatch(
        setQuery({
          query: '',
        }),
      )
    }
  }, [onClear, refine])

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  const onSearch = (e: string) => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    if (currentDistributionCenter && e) {
      dispatch(
        setQuery({
          query: e,
        }),
      )
      dispatch(resetRefinementLists())
    }
    sendSearchEvent(e, cart, entityInfo)
  }

  const debouncedRefine = useRef(debounce(onSearch, 500))

  return (
    <SearchInput
      className={className}
      inputClassName={inputClassName}
      placeholder="Busca algún producto"
      reset={clearInput}
      onChange={(e) => {
        if (onChange) onChange(e)
        if (debouncedRefine.current) {
          debouncedRefine.current(e.target.value)
        }
      }}
      CustomSearchIcon={searchIcon}
      CustomCloseIcon={() => <Icon iconId="close" id="search-close-icon" />}
      value={value}
      hasText={!!value}
      keyDownHandler={(e) => {
        if (onKeydown) onKeydown(e)
        if (e.key === 'Enter' && debouncedRefine.current && refineOnEnter) {
          debouncedRefine.current(e.currentTarget.value)
        }
      }}
      onFocus={onFocus}
      onBlur={(e) => {
        if (e.relatedTarget) {
          const { id } = e.relatedTarget as Element
          if (id && ['search-close-button', 'search-close-icon'].includes(id)) {
            clearInput()
          }
        }
        if (onBlur) onBlur(e)
      }}
    />
  )
}
