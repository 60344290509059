import React from 'react'
import styled from 'styled-components'
import { Image, ProductCart, toCssPrefix, toCurrency, breakpoints } from '@ecommerce/shared'

const { cssPrefix, toPrefix } = toCssPrefix('NoStockItem__')

type Props = {
  isBO: boolean
  product: ProductCart
}

const Wrapper = styled.div`
  padding: 14px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 18px;

  .${cssPrefix} {
    &image {
      width: 80px;
      height: 80px;
      object-fit: contain;
      margin-right: 25px;
      filter: grayscale(1);
      color: ${({ theme }) => theme.colors.black};
      font-size: 10px;
    }

    &info,
    &price {
      p,
      span {
        color: ${({ theme }) => theme.colors.platinum80};
        font-size: 12px;
        font-weight: bold;
        margin: 0;
      }
    }

    &info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      position: relative;

      .product-title {
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .product-qty {
        display: block;
        position: absolute;
        bottom: 0;
      }
    }

    &price {
      align-self: flex-end;
      margin-left: auto;
      white-space: nowrap;
    }

    &price .product-price,
    &info .product-qty {
      font-size: 16px;
      text-decoration: line-through;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.max}) {
    .${cssPrefix} {
      &info {
        .product-title {
          font-size: 10px;
        }
      }
    }
  }
`

const NoStockItem = ({ isBO, product }: Props) => {
  const price = toCurrency(product.price * (product.quantity ?? 1), isBO, isBO)

  return (
    <Wrapper>
      <Image className={toPrefix('image')} alt={product.title} preload={false} src={product.image} />
      <div className={toPrefix('info')}>
        <p className="product-title">{product.title}</p>
        <span className="product-qty">{product.quantity}</span>
      </div>
      <div className={toPrefix('price')}>
        <p className="product-price">{price}</p>
      </div>
    </Wrapper>
  )
}

export default NoStockItem
