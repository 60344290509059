import React from 'react'

const EmptyCartIcon = () => {
  return (
    <svg width="95" height="95" viewBox="0 0 124 124" fill="none">
      <circle cx="61.3876" cy="61.3879" r="61.2346" fill="#E8EBF7" fillOpacity="0.5" />
      <g opacity="0.8">
        <g opacity="0.3">
          <path
            d="M81.7481 19.901H58.1728C57.5605 19.901 57.1012 19.4418 57.1012 18.8294C57.1012 18.2171 57.5605 17.7578 58.1728 17.7578H81.9012C82.5135 17.7578 82.9728 18.2171 82.9728 18.8294C82.8197 19.4418 82.3605 19.901 81.7481 19.901Z"
            fill="#999999"
          />
          <path
            d="M50.5184 19.901H45.9259C45.3135 19.901 44.8542 19.4418 44.8542 18.8294C44.8542 18.2171 45.3135 17.7578 45.9259 17.7578H50.5184C51.1308 17.7578 51.5901 18.2171 51.5901 18.8294C51.5901 19.4418 51.1308 19.901 50.5184 19.901Z"
            fill="#999999"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.9259 12.7059H48.0691V10.7158H45.9259V12.7059ZM52.2024 12.7059H50.0592V10.7158H52.2024V12.7059ZM54.1926 12.7059H56.3358V10.7158H54.1926V12.7059ZM60.4691 12.7059H58.3259V10.7158H60.4691V12.7059ZM62.4592 12.7059H64.6024V10.7158H62.4592V12.7059ZM66.5926 12.7059H68.7358V10.7158H66.5926V12.7059Z"
            fill="#999999"
          />
        </g>
        <g opacity="0.3">
          <path
            d="M81.7481 110.835H58.1728C57.5605 110.835 57.1012 110.375 57.1012 109.763C57.1012 109.151 57.5605 108.691 58.1728 108.691H81.9012C82.5135 108.691 82.9728 109.151 82.9728 109.763C82.8197 110.375 82.3605 110.835 81.7481 110.835Z"
            fill="#999999"
          />
          <path
            d="M50.5184 110.835H45.9259C45.3135 110.835 44.8542 110.375 44.8542 109.763C44.8542 109.151 45.3135 108.691 45.9259 108.691H50.5184C51.1308 108.691 51.5901 109.151 51.5901 109.763C51.5901 110.375 51.1308 110.835 50.5184 110.835Z"
            fill="#999999"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.9259 103.639H48.0691V101.496H45.9259V103.639ZM52.2024 103.639H50.0592V101.496H52.2024V103.639ZM54.1926 103.639H56.3358V101.496H54.1926V103.639ZM60.4691 103.639H58.3259V101.496H60.4691V103.639ZM62.4592 103.639H64.6024V101.496H62.4592V103.639ZM66.5926 103.639H68.7358V101.496H66.5926V103.639Z"
            fill="#999999"
          />
        </g>
      </g>
      <g opacity="0.2">
        <path
          d="M113.896 58.0191H95.8321C95.3728 58.0191 95.0667 57.713 95.0667 57.2537C95.0667 56.7945 95.3728 56.4883 95.8321 56.4883H113.896C114.356 56.4883 114.662 56.7945 114.662 57.2537C114.662 57.5599 114.356 58.0191 113.896 58.0191Z"
          fill="#999999"
        />
        <path
          d="M104.864 53.5797H86.8C86.3407 53.5797 86.0345 53.2735 86.0345 52.8143C86.0345 52.355 86.3407 52.0488 86.8 52.0488H104.864C105.323 52.0488 105.63 52.355 105.63 52.8143C105.63 53.1204 105.323 53.5797 104.864 53.5797Z"
          fill="#999999"
        />
        <path
          d="M39.3432 58.0191H21.279C20.8197 58.0191 20.5135 57.713 20.5135 57.2537C20.5135 56.7945 20.8197 56.4883 21.279 56.4883H39.3432C39.8024 56.4883 40.1086 56.7945 40.1086 57.2537C40.1086 57.5599 39.8024 58.0191 39.3432 58.0191Z"
          fill="#999999"
        />
        <path
          d="M30.3111 53.5797H12.2469C11.7876 53.5797 11.4814 53.2735 11.4814 52.8143C11.4814 52.355 11.7876 52.0488 12.2469 52.0488H30.3111C30.7703 52.0488 31.0765 52.355 31.0765 52.8143C31.0765 53.1204 30.7703 53.5797 30.3111 53.5797Z"
          fill="#999999"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.8691 45.9257C74.0938 45.9257 75.0123 46.3849 75.7777 47.1504C76.3901 48.0689 76.6962 49.1405 76.5431 50.2121L72.4098 68.8886C72.1036 70.5726 70.5728 71.7973 68.8888 71.7973H36.8938L37.353 74.7059C37.5061 74.859 37.6592 75.0121 37.8123 75.0121H70.1135C71.032 75.0121 71.7975 75.7775 71.7975 76.6961C71.7975 77.6146 71.032 78.38 70.1135 78.38H66.5925C66.6188 78.4851 66.6451 78.5856 66.6706 78.6832C66.7936 79.1544 66.8987 79.5567 66.8987 80.064C66.8987 82.8195 64.7555 84.9627 61.9999 84.9627C59.2444 84.9627 57.1012 82.8195 57.1012 80.064C57.1012 79.4516 57.2543 78.8393 57.4073 78.38H48.6814C48.7077 78.4851 48.7339 78.5856 48.7594 78.6832C48.8825 79.1544 48.9876 79.5567 48.9876 80.064C48.9876 82.8195 46.8444 84.9627 44.0888 84.9627C41.3333 84.9627 39.1901 82.8195 39.1901 80.064C39.1901 79.4516 39.3431 78.8393 39.4962 78.38H37.8123C35.9752 78.38 34.4444 77.0022 34.1382 75.1652L33.5259 70.4195L30.4641 47.7627L29.8518 43.7825C29.6987 43.6294 29.5456 43.4763 29.3925 43.4763H26.3308C25.4123 43.4763 24.6469 42.7109 24.6469 41.7923C24.6469 40.8738 25.4123 40.1084 26.3308 40.1084H29.3925C31.2296 40.1084 32.7604 41.4862 33.0666 43.3232L33.3728 45.9257H72.8691ZM70.1135 67.2047L73.3283 50.9775C73.4814 50.2121 72.8691 49.2936 72.1036 49.2936C71.3382 49.1405 70.4197 49.5998 70.2666 50.5183L67.0518 66.7454C66.8987 67.5109 67.358 68.4294 68.2765 68.5825H68.5827C69.3481 68.5825 69.9604 67.9701 70.1135 67.2047Z"
        fill="#999999"
      />
      <path
        d="M86.8 30.4648C92.158 30.4648 96.5975 34.9043 96.5975 40.2624C96.5975 45.6204 92.158 50.0599 86.8 50.0599C81.4419 50.0599 77.0024 45.6204 77.0024 40.2624C77.1555 34.7513 81.4419 30.4648 86.8 30.4648Z"
        fill="#FD0E22"
      />
      <path
        d="M84.8098 35.823L86.0345 37.66C86.3407 38.1193 86.6469 38.5786 86.7999 39.0378C87.1061 38.5786 87.4123 38.1193 87.5654 37.66L88.7901 35.823H90.474L87.7185 39.8033L90.6271 44.0897H88.9431L87.7185 42.2526C87.4123 41.7934 87.1061 41.3341 86.7999 40.8749C86.4938 41.3341 86.1876 41.7934 85.8814 42.2526L84.6567 44.0897H82.9728L85.8814 39.8033L83.1259 35.6699L84.8098 35.823Z"
        fill="white"
      />
    </svg>
  )
}

export default EmptyCartIcon
