import React, { useState } from 'react'
import { getStoredZone, hexToRGBA } from '@ecommerce/shared'
import styled from 'styled-components'
import { Icon } from '../Icon/Icon'
import LocationSelectorWidget from '../LocationSelector'

export const MobileLocationWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 0.5px solid ${({ theme }) => hexToRGBA(theme.colors.black40, 0.25)};
  svg {
    margin-inline-start: 27px;
  }
  > span {
    height: inherit;
    margin-inline-start: 16px;
    font-size: 13px;
    line-height: 32px;
    letter-spacing: -0.06px;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.platinum80};
    font-weight: bold;
  }
`

const Text = styled.p`
  height: inherit;
  margin-inline-start: 11px;
  margin-block: 0;
  align-items: center;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.1px;
`

const CurrentZone = styled.span`
  color: ${({ theme }) => theme.colors.red};
  font-weight: bold;
`

const ChangeLocation = styled.span`
  height: inherit;
  margin-inline-start: 16px;
  font-size: 13px;
  line-height: 32px;
  letter-spacing: -0.06px;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.platinum80};
  font-weight: bold;
`

type Props = {
  className?: string
}

const MobileLocationSelector = ({ className }: Props) => {
  const currentZone = getStoredZone()

  const [openLocationSelector, setOpenLocationSelector] = useState(false)

  return (
    <MobileLocationWrapper className={`location-selector ${className}`}>
      <Icon iconId="location" size="22" fillColor="red" />
      <Text>
        Entrega en
        <CurrentZone>
          &nbsp;
          {currentZone?.name}
        </CurrentZone>
      </Text>
      <ChangeLocation
        onClick={() => {
          setOpenLocationSelector(true)
        }}
      >
        Cambiar
      </ChangeLocation>
      {openLocationSelector && <LocationSelectorWidget onClose={() => setOpenLocationSelector(false)} />}
    </MobileLocationWrapper>
  )
}

export default MobileLocationSelector
