import React from 'react'
import { toCssPrefix } from '@ecommerce/shared'
import styled from 'styled-components'
import { SubcategoryType } from '../../types/PgPages'
import { Icon } from '../Icon/Icon'

const { cssPrefix, toPrefix } = toCssPrefix('Subcategory__')

type StyledProps = {
  isRight?: boolean
}

const Wrapper = styled.div<StyledProps>`
  color: ${({ theme }) => theme.colors.black80};
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin: 24px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  max-width: 110px;
  button {
    text-align: left;
  }
  .${cssPrefix} {
    &title {
      font-weight: 600;
    }
    &separator {
      height: 1px;
      width: 100px;
      background: ${({ theme }) => theme.colors.platinum60};
      margin-top: 8px;
      margin-bottom: 20px;
    }
    &item {
      text-decoration: none;
      margin-bottom: 8px;
      letter-spacing: -0.15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${({ theme }) => theme.colors.black80};
    }
    &item:visited {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.black80};
    }
    &item:hover {
      font-weight: 600;
    }
    &final-item {
      letter-spacing: -0.15px;
      display: flex;
      color: ${({ theme }) => theme.colors.red};
      text-decoration: none;
    }
    &final-item:hover {
      font-weight: 600;
    }
    &final-item:active {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`

type Props = {
  subcategory: SubcategoryType
  categorySlug: string
  key?: string
}

type filterType = {
  name: string
  filter: string
  label: string
}

const Subcategory = ({ subcategory, categorySlug, key }: Props) => {
  const { attributeToFilter, label, filters, categorySlug: subcategorySlug } = subcategory

  const onNavigate = (data?: filterType) => {
    const slug = subcategorySlug ?? categorySlug
    if (slug) {
      if (data) {
        const { filter } = data
        return slug.startsWith('/')
          ? `${slug}?${attributeToFilter}=${filter}`
          : `/category/${slug}?${attributeToFilter}=${filter.toUpperCase()}`
      }
      if (!data) {
        return `/category/${slug}`
      }
    }
  }

  return (
    <Wrapper key={key}>
      <span className={toPrefix('title')}>{label}</span>
      <div className={toPrefix('separator')} />
      {filters.map((filter, index) => {
        if (index < 4)
          return (
            <a key={`${index}-${filter.label}`} className={toPrefix('item')} href={onNavigate(filter)}>
              {filter.label}
            </a>
          )
        return null
      })}
      <a className={toPrefix('final-item')} href={onNavigate()}>
        Ver todo
        <Icon iconId="chevron_right" color="red" size="24" />
      </a>
    </Wrapper>
  )
}

export default Subcategory
