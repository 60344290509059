import React from 'react'
import styled from 'styled-components'
import { toCssPrefix, hasScrollbarStyles, ProductCart, Button } from '@ecommerce/shared'
import NoStockItem from './NoStockItem'

const { toPrefix, cssPrefix } = toCssPrefix('NoStockProducts__')

type Props = {
  products: ProductCart[]
  isBO: boolean
  className?: string
  actionText?: string
  title?: string
  onDeleteAll?: () => void
  isLoading?: boolean
}

const Wrapper = styled.div`
  background: #8290a4;
  padding: 8px 40px;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  ${hasScrollbarStyles}

  .${cssPrefix} {
    &products-qty {
      font-size: 14px;
      font-weight: bold;
    }

    &top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 14px;

      .delete-all-btn {
        background: ${({ theme }) => theme.colors.white};
        width: 130px;
        font-size: 14px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        &:hover {
          color: ${({ theme }) => theme.colors.red};
        }
      }
    }

    &action-text {
      font-size: 14px;
      margin: 0 0 12px;
    }
  }
`

const NoStockProducts = ({
  products,
  isBO,
  className = '',
  actionText,
  title,
  onDeleteAll,
  isLoading = false,
}: Props) => (
  <Wrapper className={`${className} ${cssPrefix}`}>
    <div className={toPrefix('top')}>
      <p className={toPrefix('products-qty')}>{title ?? `Productos sin stock eliminados: ${products.length}`}</p>
      {onDeleteAll && (
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          onClick={onDeleteAll}
          className="delete-all-btn"
          btnType="tertiary"
          btnColor="red"
        >
          Eliminar todos
        </Button>
      )}
    </div>
    {actionText && <p className={toPrefix('action-text')}>{actionText}</p>}
    <div className={toPrefix('products-list')}>
      {products.map((product) => (
        <NoStockItem isBO={isBO} key={product.lineItemId || product.skuCode} product={product} />
      ))}
    </div>
  </Wrapper>
)

export default NoStockProducts
