import React from 'react'
import { Button } from '@ecommerce/shared'
import { navigate } from 'gatsby'
import EmptyCartIcon from '../../templates/cart/EmptyCart/EmptyCartIcon'
import { BoxEmptyIcon, ContainerEmptyCart, EmptyInfo, EmptySubtitle, EmptyTitle } from './EmptyCart.styled'

const EmptyCart = () => {
  return (
    <ContainerEmptyCart className="shopping-cart-empty-product">
      <EmptyInfo>
        <BoxEmptyIcon>
          <EmptyCartIcon />
        </BoxEmptyIcon>
        <EmptyTitle className="shopping-cart-empty-product-cart">Tu carro está vacío</EmptyTitle>
        <EmptySubtitle className="shopping-cart-empty-product-interest">
          ¡Descubre todos los productos que te podrían interesar!
        </EmptySubtitle>
        <Button onClick={() => navigate('/promotion')} className="button-show-catalog" type="button">
          Ver catálogo
        </Button>
      </EmptyInfo>
    </ContainerEmptyCart>
  )
}

export default EmptyCart
