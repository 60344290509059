import React, { useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { toCssPrefix, capitalize, useLocation, useAuth, getStoredZone } from '@ecommerce/shared'
import { Sidebar } from '../Sidebar'
import { menuItems, accountItems, highlightedItems } from './menuItems'
import { MenuTile, MenuTileProps } from '../Sidebar/MenuTile'
import { CategoryTile } from './CategoryTile'
import { FlatLocationProductCategory } from '../../types/PgPages'
import { logoutMenuItem } from '../MyAccountSidebar/menuItems'
import { sendMiBarEvent, sendCategoryClickEvent } from '../../utils/events'

const { cssPrefix, toPrefix } = toCssPrefix('MenuMobile__')

const Wrapper = styled.div`
  padding: 0px 0px 64px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 72px);
  .separator {
    min-height: 1px;
    width: 100%;
    background: ${({ theme }) => theme.colors.grey};
    margin: 4px 0 15px;
  }
  .space {
    width: 100%;
    margin: 15px 0px 0px;
  }
  .bold {
    font-weight: 600;
  }
  .${cssPrefix} {
    &label {
      padding: 0px 24px;
      font-weight: 600;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: -0.24px;
      color: ${({ theme }) => theme.colors.black80};
      margin: 9px 0 8px;
    }
    &content {
      overflow-y: scroll;
      max-height: calc(100vh - 187px);
    }
    &subtitle {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-bottom: 14px;
      .icon {
        margin-right: 14px;
      }
    }
  }
`

type Props = {
  onClose: () => void
  onBlur: () => void
  logoUrl?: string
  onNavigationClick?: (slug: string) => void
  onSignOut?: () => void
  categories?: FlatLocationProductCategory[]
}

const MenuMobile = ({ onClose, onBlur, logoUrl, onNavigationClick, categories, onSignOut }: Props) => {
  const [showAccount, setShowAccount] = useState(false)
  const { isBolivia } = useLocation()
  const {
    state: { firstName, lastName },
  } = useAuth()

  const currentZone = getStoredZone()

  const handleTileClick = (item: MenuTileProps) => {
    if (item.actionType === 'mi-bar' && item.slug && onNavigationClick) {
      sendMiBarEvent()
      onNavigationClick(item.slug)
    }
    if (item.actionType === 'navigation' && item.slug && onNavigationClick) onNavigationClick(item.slug)
    if (item.actionType === 'account' && !firstName && onNavigationClick) onNavigationClick('login')
    if (item.actionType === 'account' && firstName) setShowAccount(true)
    if (item.actionType === 'home') navigate(`/`)
    if (item.actionType === 'signout' && onSignOut) onSignOut()
  }

  const formatMenuItemText = (item: MenuTileProps) => {
    if (item.actionType && item.actionType === 'location' && item.text.includes('$location')) {
      const arr = item.text.split('$location')
      return arr.join(capitalize(currentZone ? currentZone.name : '') || '')
    }
    if (item.slug && item.slug === 'promotion' && isBolivia()) {
      return 'Ofertas'
    }
    return item.text
  }

  const onNavigate = (slug: string, name: string) => {
    sendCategoryClickEvent(slug, name)
    navigate(slug.startsWith('/') ? `${slug}` : `/category/${slug}`)
  }

  return (
    <div>
      <Sidebar onClose={onClose} onBlur={onBlur} logoUrl={logoUrl}>
        <Wrapper>
          {firstName && (
            <>
              <span className={toPrefix('label')}>{`${firstName} ${lastName}`}</span>
              <span className="separator" />
            </>
          )}

          {highlightedItems.some((item) => item.isVisible) && (
            <>
              {highlightedItems.map((item) => {
                if (!item.isVisible) {
                  return null
                }
                return (
                  <MenuTile
                    key={item.text}
                    iconId={item.iconId}
                    text={formatMenuItemText(item)}
                    onClick={() => handleTileClick(item)}
                    actionType={item.actionType}
                    hasArrow={item.actionType === 'account' && !!firstName}
                    highlightColor={item.highlightColor}
                  />
                )
              })}
              <span className="space" />
            </>
          )}

          <span className={toPrefix('label')}>Categorías</span>
          <div className={toPrefix('menu')}>
            {categories &&
              categories.map((item) => (
                <CategoryTile
                  key={item.slug}
                  slug={item.slug}
                  image={item.image}
                  name={item.name}
                  onClick={() => onNavigate(item.slug, item.name)}
                />
              ))}
          </div>
          <span className="separator" />
          <div className={toPrefix('menu')}>
            {menuItems.map((item) => {
              if ((isBolivia() && item.actionType === 'mi-bar') || !item.isVisible) {
                return null
              }
              return (
                <MenuTile
                  key={item.text}
                  iconId={item.iconId}
                  text={formatMenuItemText(item)}
                  onClick={() => handleTileClick(item)}
                  actionType={item.actionType}
                  hasArrow={item.actionType === 'account' && !!firstName}
                />
              )
            })}
          </div>
          {firstName && (
            <>
              <span className="separator" />
              <MenuTile
                key="logout-menu-mobile"
                onClick={() => handleTileClick(logoutMenuItem)}
                iconId="logout"
                text="Cerrar sesión"
                actionType="signout"
              />
            </>
          )}
        </Wrapper>
      </Sidebar>
      {showAccount && (
        <Sidebar onClose={onBlur} onBlur={onBlur} backMessage="Menú principal" onBack={setShowAccount}>
          <Wrapper>
            <MenuTile key="user-menu-mobile" className="bold" iconId="user" text="Mi cuenta" actionType="account" />
            <span className="separator" />
            <div className={toPrefix('menu')}>
              {accountItems &&
                accountItems.map((item) => {
                  if (!isBolivia() && item.slug === 'mis-facturas') {
                    return null
                  }
                  return (
                    <MenuTile
                      key={item.text}
                      iconId={item.iconId}
                      text={formatMenuItemText(item)}
                      onClick={() => handleTileClick(item)}
                      actionType={item.actionType}
                    />
                  )
                })}
            </div>
          </Wrapper>
        </Sidebar>
      )}
    </div>
  )
}

export default MenuMobile
