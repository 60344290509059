import React from 'react'
import { toCssPrefix, breakpoints, useLocation } from '@ecommerce/shared'
import styled from 'styled-components'
import { FlatLocationProductCategory } from '../../types/PgPages'
import Tab from './Tab'
import { ZIndex } from '../../types/Constants'

const { cssPrefix, toPrefix } = toCssPrefix('MenuDesktop__')

const Wrapper = styled.div`
  display: none;
  @media (${breakpoints.desktop.min}) {
    display: block;
    width: 100%;
    position: fixed;
    top: 72px;
    left: 0;
    z-index: ${ZIndex.medium};
    .${cssPrefix} {
      &container {
        position: relative;
        background: ${({ theme }) => theme.colors.black};
        width: 100%;
        color: ${({ theme }) => theme.colors.white};
        display: flex;
        justify-content: space-around;
        align-items: center;
        max-height: 42px;
        padding: 0 15px;
      }
    }
  }
`

interface Props {
  categories: FlatLocationProductCategory[]
}

const MenuDesktop = ({ categories }: Props) => {
  const { textByCountry } = useLocation()

  const promotionCategory: FlatLocationProductCategory = {
    name: textByCountry('Promociones', 'Ofertas'),
    slug: 'promotion',
    mainCategoryNames: ['promotion'],
    image: 'promotion',
  }

  return (
    <Wrapper>
      <div className={toPrefix('container')}>
        <Tab category={promotionCategory} icon="Promociones" />
        {categories &&
          categories.map((category, index) => <Tab category={category} key={`${index}-${category.slug}`} />)}
      </div>
    </Wrapper>
  )
}

export default MenuDesktop
