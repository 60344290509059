import React, { useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { toCssPrefix, ZIndex, Anchor, breakpoints } from '@ecommerce/shared'
import { Icon } from '../../Icon/Icon'
import { NavBarMenuTab, NavBarMenuTabProps } from './NavBarTab'

const { cssPrefix, toPrefix } = toCssPrefix('NavbarMenu__')

const Wrapper = styled.div`
  width: 100vw;
  height: 40px;
  z-index: ${ZIndex.high};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .${cssPrefix} {
    &container {
      background: ${({ theme }) => theme.colors.grey};
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      gap: 24px;
      box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
      z-index: ${ZIndex.high};

      @media (${breakpoints.tabletPortrait.min}) {
        gap: 32px;
      }
    }
    &dropdown {
      top: 40px;
      width: 100vw;
      background: ${({ theme }) => theme.colors.grey};
      position: absolute;
      z-index: ${ZIndex.low};
      min-height: 40px;
      box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
      &-inner {
        max-height: calc(100vh - 144px);
        overflow: scroll;
      }
      &-close {
        position: absolute;
        color: ${({ theme }) => theme.colors.brand.default};
        top: calc(100% - 30px);
        right: 12px;
      }
    }
  }
`

export interface NavBarMenuProps {
  tabs: (NavBarMenuTabProps & { children?: React.ReactNode })[]
  className?: string
  animationsTime?: number
}

export function NavBarMenu({ tabs, className, animationsTime }: NavBarMenuProps) {
  const [dropdownContent, setDropdownContent] = useState<React.ReactNode | null>()
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  return (
    <Wrapper className={className}>
      <div className={toPrefix('container')}>
        {tabs?.map((tab, idx) => {
          if (tab) {
            return (
              <NavBarMenuTab
                iconId={tab.iconId}
                key={`${tab.label}${idx}`}
                label={tab.label}
                iconSize={22}
                isActive={tab.isActive || activeIndex === idx}
                className={toPrefix('tab')}
                onClick={(e) => {
                  if (tab.children) {
                    if (dropdownContent === tab.children) {
                      setDropdownContent(null)
                      setActiveIndex(null)
                    } else {
                      setDropdownContent(tab.children)
                      setActiveIndex(idx)
                    }
                  }
                  if (tab.onClick) tab.onClick(e)
                }}
              />
            )
          }
        })}
      </div>
      <AnimatePresence>
        {!!dropdownContent && (
          <motion.div
            className={toPrefix('dropdown')}
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{ duration: animationsTime ?? 0.15 }}
          >
            <div className={toPrefix('dropdown-inner')}>{dropdownContent}</div>
            <Anchor
              className={toPrefix('dropdown-close')}
              onClick={() => {
                setDropdownContent(null)
                setActiveIndex(null)
              }}
            >
              <Icon iconId="chevron_up" size="22" />
            </Anchor>
          </motion.div>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}
