import React, { useState } from 'react'
import styled from 'styled-components'
import { ProductCart } from '@ecommerce/shared'
import NoStockProducts from '../NoStockProducts'

type Props = {
  isBO: boolean
  products: ProductCart[]
  onDelete: () => void
}

const Wrapper = styled.div`
  .NoStockProductsCart {
    padding: 8px 15px;
  }
`

const OutOfStockProducts = ({ isBO, products, onDelete }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDelete = async () => {
    if (!isLoading) {
      setIsLoading(true)
      onDelete()
      setIsLoading(false)
    }
  }

  if (products?.length === 0) return null

  return (
    <Wrapper>
      <NoStockProducts
        className="NoStockProductsCart"
        isBO={isBO}
        onDeleteAll={handleDelete}
        actionText="Para continuar elimina los productos sin stock"
        title={`Productos sin stock: ${products.length}`}
        products={products}
        isLoading={isLoading}
      />
    </Wrapper>
  )
}

export default OutOfStockProducts
