import { Country } from '@ecommerce/shared'
import { MenuTileProps } from '../Sidebar/MenuTile'
import secrets from '../../config/secrets'

const { COUNTRY } = secrets

export const baseMenuItems: MenuTileProps[] = [
  { iconId: 'home', text: 'Inicio', actionType: 'home', slug: '', isVisible: true },
  {
    iconId: 'truck',
    text: 'Seguimiento en línea',
    actionType: 'navigation',
    slug: 'tracking',
  },
  {
    iconId: 'glass-cup',
    text: 'mi Bar',
    actionType: 'mi-bar',
    slug: 'mi-bar',
    isVisible: COUNTRY === Country.BOLIVIA,
  },
  {
    iconId: 'user',
    text: 'Mi cuenta',
    actionType: 'account',
    slug: 'account',
    isVisible: true,
  },
]

export const menuItems: MenuTileProps[] = [...baseMenuItems]

export const baseAccountItems: MenuTileProps[] = [
  {
    iconId: 'edit',
    text: 'Mis datos',
    actionType: 'navigation',
    slug: 'my-account',
    isVisible: true,
  },
  {
    iconId: 'truck',
    text: 'Mis direcciones',
    actionType: 'navigation',
    slug: 'mis-direcciones',
    isVisible: true,
  },
  {
    iconId: 'cart_0',
    text: 'Mis pedidos',
    actionType: 'navigation',
    slug: 'my-orders',
    isVisible: true,
  },
  { iconId: 'heart_outline', text: 'Mis favoritos', actionType: 'navigation', slug: 'mis-favoritos', isVisible: true },
  {
    iconId: 'invoice',
    text: 'Mis facturas',
    actionType: 'navigation',
    slug: 'mis-facturas',
    isVisible: true,
  },
]

export const accountItems: MenuTileProps[] = [...baseAccountItems]

export const highlightedItems: MenuTileProps[] = [
  {
    iconId: 'returnability',
    text: 'Retornables',
    actionType: 'navigation',
    slug: 'returnability',
    isVisible: !(COUNTRY === Country.BOLIVIA),
    highlightColor: 'green',
  },
  {
    iconId: 'glass-cup',
    text: 'mi Bar',
    actionType: 'mi-bar',
    slug: 'mi-bar',
    isVisible: !(COUNTRY === Country.BOLIVIA),
    highlightColor: 'black',
  },
  {
    iconId: 'Promociones',
    text: 'Promociones',
    actionType: 'navigation',
    slug: 'promotion',
    isVisible: true,
    highlightColor: 'black',
  },
  {
    iconId: 'returnability',
    text: 'Retornables',
    actionType: 'navigation',
    slug: 'returnability',
    isVisible: !(COUNTRY === Country.CHILE),
    highlightColor: 'green',
  },
]
