import { navigate } from 'gatsby'
import { useLocation } from '@ecommerce/shared'
import { MainNavbarProps } from '../components/NavBar/MainNavbar'
import { sendMiBarEvent, sendReturnabilityClickEvent } from '../utils/events'

export interface UseMobileNavbarMenuProps {
  isMoment?: boolean
  disableControls?: boolean
}

export const useMobileNavbarMenu = ({ isMoment, disableControls }: UseMobileNavbarMenuProps) => {
  const { isBolivia } = useLocation()
  return [
    ...(isMoment
      ? [
          {
            iconId: 'home',
            label: 'Inicio',
            onClick: disableControls ? '' : () => navigate(`/`),
          },
        ]
      : []),
    {
      iconId: 'returnability',
      label: 'Retornables',
      onClick: () => {
        if (disableControls) return
        sendReturnabilityClickEvent()
        navigate(`/returnability`)
      },
      isActive: !disableControls && typeof window !== 'undefined' && window.location.href.includes(`/returnability`),
    },
    isBolivia()
      ? null
      : {
          iconId: 'glass-cup',
          label: 'mi Bar',
          onClick: () => {
            if (disableControls) return
            sendMiBarEvent()
            navigate(`/mi-bar`)
          },
          isActive: !disableControls && typeof window !== 'undefined' && window.location.href.includes(`/mi-bar`),
        },
  ] as MainNavbarProps['tabs']
}
