import React, { HTMLAttributes, useRef, useState } from 'react'
import styled, { css, DefaultTheme } from 'styled-components'
import { Anchor, toCssPrefix, breakpoints } from '@ecommerce/shared'
import { Icon, IconProps } from '../../Icon/Icon'

const { cssPrefix, toPrefix } = toCssPrefix('NavBarMenuTab__')

type StyledProps = {
  isActive?: boolean
  activeHighlight?: 'background' | 'border'
  iconColor?: IconProps['fillColor']
}

const styledColor = (
  theme: DefaultTheme,
  iconColor?: IconProps['fillColor'],
  isActive?: boolean,
  activeHighlight?: string,
) => {
  if (isActive && activeHighlight === 'background') return theme.colors.iconsHoverColor
  return theme.colors.iconsColor
}

const Wrapper = styled.div<StyledProps>`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  ${({ theme, isActive, activeHighlight }) => {
    if (isActive) {
      if (activeHighlight === 'background')
        return css`
          .${cssPrefix} {
            &anchor {
              background: ${theme.colors.grey20};
              &:hover {
                color: ${theme.colors.iconsHoverColor || theme.colors.black80};
              }
            }
          }
        `
      return css`
        border-bottom: 3px solid ${theme.colors.brand.default};
        &anchor {
          color: ${theme.colors.iconsHoverColor || theme.colors.black80};
        }
      `
    }
    return css`
      height: 100%;
      border: none;
    `
  }}
  .${cssPrefix} {
    &anchor {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: ${({ theme, iconColor, isActive, activeHighlight }) =>
        styledColor(theme, iconColor, isActive, activeHighlight)};
    }

    &label {
      margin-top: 0;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
    }
    @media (${breakpoints.desktop.min}) {
      &anchor {
        flex-direction: column;
      }
      &label {
        margin-top: 5px;
      }
    }

    &dropdown {
      position: absolute;
      top: 65px;
      left: 0;
      background-color: ${({ theme }) => theme.colors.grey20};
      width: 552px;
      border-radius: 0px 4px 4px 4px;
      box-shadow: 0px 4px 8px rgba(3, 15, 28, 0.15);
    }
  }
`
export interface NavBarMenuTabProps extends HTMLAttributes<HTMLDivElement | HTMLButtonElement>, StyledProps {
  onClick?: (e: React.MouseEvent) => void
  iconId: IconProps['iconId']
  iconSize?: string | number
  label?: string
  className?: string
  anchorClassName?: string
  dropdownContent?: React.ReactNode
}

export const NavBarMenuTab = ({
  iconId,
  iconColor,
  onClick,
  onMouseEnter,
  onMouseLeave,
  label,
  className,
  anchorClassName,
  iconSize,
  activeHighlight,
  dropdownContent,
}: NavBarMenuTabProps) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [isActive, setIsActive] = useState(false)
  const mouseInside = useRef(false)
  const [closeDropdownTimeout, setCloseDropdownTimeout] = useState(0)

  const wrapperMouseEnter = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    clearTimeout(closeDropdownTimeout)
    setIsActive(true)
    mouseInside.current = true
    if (onMouseEnter) {
      onMouseEnter(e)
    }
  }

  const wrapperMouseLeave = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    clearTimeout(closeDropdownTimeout)
    mouseInside.current = false
    setCloseDropdownTimeout(
      setTimeout(
        () => {
          if (mouseInside.current) {
            return
          }
          setIsActive(false)
        },
        dropdownContent ? 300 : 0,
      ),
    )
    if (onMouseLeave) {
      onMouseLeave(e)
    }
  }

  return (
    <Wrapper
      data-testid="navbar-tab"
      isActive={isActive}
      activeHighlight={activeHighlight}
      className={className}
      iconColor={iconColor}
      ref={wrapperRef}
      onMouseEnter={wrapperMouseEnter}
      onMouseLeave={wrapperMouseLeave}
    >
      <Anchor
        className={`${toPrefix('anchor')} ${anchorClassName ?? ''}`}
        onClick={onClick}
        onMouseEnter={wrapperMouseEnter}
      >
        <Icon iconId={iconId} size={iconSize} />
        <span className={toPrefix('label')}>{label}</span>
      </Anchor>
      {!!dropdownContent && isActive && (
        <div
          className={toPrefix('dropdown')}
          ref={dropdownRef}
          onMouseEnter={wrapperMouseEnter}
          onMouseLeave={wrapperMouseLeave}
        >
          {dropdownContent}
        </div>
      )}
    </Wrapper>
  )
}
