/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef } from 'react'
import { useResolution, useShoppingCart, useLocation, useAuth, toCurrencyNumber, useCart } from '@ecommerce/shared'
import { useCartStatus } from '@ecommerce/shared/src/context/ShoppingCart/cartStatusContext'
import { useUI } from '@ecommerce/shared/src/context/UI/ui.context'
import { IconClose } from '../Icons'
import Summary from './Summary'
import { sendRemoveProductEvent, sendViewCartEvent } from '../../utils/events'
import { Icon } from '../Icon/Icon'
import ShoppingContent from './ShoppingContent'
import EmptyCartAlert from '../../templates/cart/Alerts/EmptyCartAlert'
import MobileLocationSelector from '../MobileLocationSelector/MobileLocationSelector'
import { ButtonClose, ContainerCart, HeaderClose } from './ShoppingCart.styled'

const ShoppingCart = () => {
  const { state: authState } = useAuth()
  const { displayCartDrawer, closeDrawerCart } = useUI()
  const { openEmptyCartAlert, setOpenEmptyCartAlert } = useCart()

  const resolution = useResolution()
  const shoppingCart = useShoppingCart()

  const customerEmail = useRef(authState.email)

  const { isLoadingTotalPrice } = useCartStatus()

  const { isBolivia } = useLocation()

  const isBO = isBolivia()

  const { state, empty: onDeleteAll, revalidateOrder } = shoppingCart

  const { byHash, globalQuantity, promotions } = state

  useEffect(() => {
    if (authState.email) customerEmail.current = authState.email
  }, [authState])

  function animateCar() {
    if (displayCartDrawer) {
      if (resolution.isMobile) {
        return {
          x: [0, 0],
        }
      }
      return {
        x: [400, 0],
      }
    }
    return {
      x: [0, 0],
    }
  }

  function onRemoveAllProducts() {
    sendRemoveProductEvent(Object.values(state.byHash), isBO)
    setOpenEmptyCartAlert(false)
    return onDeleteAll()
  }

  const skus = Object.keys(byHash)

  useEffect(() => {
    if (displayCartDrawer) {
      if (Object.values(byHash).length > 0) {
        revalidateOrder()
        sendViewCartEvent(
          Object.values(byHash).map((product) => {
            return { ...product, price: toCurrencyNumber(product.price, isBO) }
          }),
        )
      }
    }
  }, [displayCartDrawer])

  return (
    <ContainerCart
      isOpen={displayCartDrawer}
      animate={animateCar()}
      transition={{ duration: resolution.isMobile ? 0 : 0.2 }}
    >
      {openEmptyCartAlert && (
        <EmptyCartAlert
          onConfirm={() => onRemoveAllProducts()}
          onClose={() => setOpenEmptyCartAlert(false)}
          title="Vaciar carro"
          message="¿Estás seguro que quieres vaciar tu carro de compras?"
          icon={<Icon iconId="trash" size={25} fillColor="green" />}
        />
      )}
      <HeaderClose>
        <span>
          Mi Carro <span>({globalQuantity} productos)</span>
        </span>
        {(resolution.isTablet || resolution.isDesktop) && (
          <ButtonClose whileTap={{ scale: 0.9 }} onClick={closeDrawerCart}>
            <IconClose />
          </ButtonClose>
        )}
      </HeaderClose>
      <MobileLocationSelector className="location-selector" />
      <ShoppingContent className="shopping-cart-items" />
      {skus.length !== 0 ? (
        <Summary
          className="summary"
          isDisabled={isLoadingTotalPrice}
          isLoading={isLoadingTotalPrice}
          promotions={promotions}
        />
      ) : null}
    </ContainerCart>
  )
}

export default ShoppingCart
